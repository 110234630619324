// import { BsPen } from "react-icons/bs";
// import * as React from "react";
// import user_image from "../../Assets/Images/user-image-four.png";
// import { useContext, useEffect, useState } from "react";
// import "../Profile/Profile.scss";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import userContext from "../../components/UseContext/UseContext";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import Button from "@mui/material/Button";
// import {
//   Container,
//   Row,
//   Col,
//   ListGroup,
//   Card,
//   Form,
//   Accordion,
// } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// // import "./DashBoard.css";
// import { motion } from "framer-motion";
// import { TextField, Box, Modal } from "@mui/material";
// import "react-toastify/dist/ReactToastify.css";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Typography from "@mui/material/Typography";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import "./Profile.scss";
// import DashBoard from "./DashBoard/DashBoard";
// import OrderHistory from "./Order/OrderHistory";
// import Inquiry from "./Inquiry/Inquiry";

// function Profile() {
//   const [user, setUser] = useState();
//   const [logOut, setLogOut] = useState(false);
//   const navigate = useNavigate();
//   const ProfileUseContext = useContext(userContext);
//   const refreshToken = localStorage.getItem("refreshToken");

//   const [activeContent, setActiveContent] = useState("profile");
//   const [isAccordion, setIsAccordion] = useState(window.innerWidth >= 992);
//   const [editProfilePage, setEditProfilePage] = useState(false);
//   const [showPasswordContainer, setShowPasswordContainer] = useState(false);

//   const [hide, setHide] = useState(false);
//   const [currentPassword, setCurrentPassword] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const isLoggedIn = localStorage.getItem("userId");

//   useEffect(() => {
//     const handleResize = () => {
//       setIsAccordion(window.innerWidth >= 992);
//     };

//     window.addEventListener("resize", handleResize);
//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     const userId = localStorage.getItem("userId");

//     if (!userId) {
//       navigate("/");
//       return;
//     }

//     const headers = {
//       Authorization: `Bearer ${userId}`,
//       "Content-Type": "application/json",
//     };

//     // console.log("header are come in profile page",headers)
//     axios
//       .get(`${API_BASE_URL}/myprofile`, { headers })
//       .then((result) => {
//         setUser(result?.data?.user);
//       })
//       .catch((error) => {
//         console.log("error on get profile", error.response.status);
//         if (error.response.status === 401) {
//           localStorage.setItem("userId", "");
//           navigate("/loginpage");
//         }
//       });
//   }, [logOut, ProfileUseContext.profileActive, refreshToken]);

//   const handleLogout = () => {
//     localStorage.removeItem("userId");
//     localStorage.removeItem("refreshToken");
//     setLogOut(true);
//   };

//   const handleOpen = () => setEditProfilePage(true);
//   const handleClose = () => setEditProfilePage(false);

//   const handleOpenPasswordContainer = () => setShowPasswordContainer(true);
//   const handleClosePasswordContainer = () => {
//     resetPasswordFields();
//     setShowPasswordContainer(false);
//   };
//   const resetPasswordFields = () => {
//     setCurrentPassword("");
//     setNewPassword("");
//     setConfirmPassword("");
//     setPasswordError("");
//   };

//   // const handleChangePassword = async () => {
//   //   if (currentPassword && newPassword && confirmPassword) {
//   //     if (newPassword !== confirmPassword) {
//   //       setPasswordError("New password and confirm password do not match.");
//   //       return;
//   //     }
//   //     try {
//   //       const userId = localStorage.getItem("userId");
//   //       const headers = {
//   //         Authorization: `Bearer ${userId}`,
//   //         "Content-Type": "application/json",
//   //       };
//   //       const updatePasswordResponse = await axios.put(
//   //         `${API_BASE_URL}/password/change`,
//   //         {
//   //           currentPassword: currentPassword,
//   //           newPassword: newPassword,
//   //         },
//   //         { headers }
//   //       );
//   //       console.log("Password updated:", updatePasswordResponse.data);
//   //       Swal.fire({
//   //         icon: "success",
//   //         title: "Password Changed Successfully",
//   //         showConfirmButton: false,
//   //         timer: 1500,
//   //       });
//   //       resetPasswordFields();
//   //       handleClose();
//   //     } catch (error) {
//   //       Swal.fire({
//   //         icon: "error",
//   //         title: "Oops...",
//   //         text: error.response.data.error,
//   //       });

//   //       // console.error("Error updating password:", error.response.data.error);
//   //     }
//   //   }
//   // };

//   const handleChangePassword = async () => {
//     if (currentPassword && newPassword && confirmPassword) {
//       if (newPassword !== confirmPassword) {
//         setPasswordError("New password and confirm password do not match.");
//         return;
//       }

//       try {
//         const userId = localStorage.getItem("userId");
//         const headers = {
//           Authorization: `Bearer ${userId}`,
//           "Content-Type": "application/json",
//         };

//         const updatePasswordResponse = await axios.put(
//           `${API_BASE_URL}/password/change`,
//           {
//             currentPassword,
//             newPassword,
//           },
//           { headers }
//         );

//         console.log("Password updated:", updatePasswordResponse.data);
//         Swal.fire({
//           icon: "success",
//           title: "Password Changed Successfully",
//           showConfirmButton: false,
//           timer: 1500,
//         });

//         resetPasswordFields();
//         handleClosePasswordContainer();
//       } catch (error) {
//         if (true) {
//           setPasswordError(`${error.response.data.error}`);
//           return;
//         }
//         console.error("Error updating password:", error.response.data.error);
//       }
//     }
//   };

//   const validationSchema = Yup.object({
//     name: Yup.string().required("Name is required"),
//     email: Yup.string()
//       .matches(
//         /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
//         "Invalid email address"
//       )
//       .required("Email is required"),
//     mobile: Yup.string()
//       .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
//       .required("Phone number is required"),
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: user?.name || "",
//       email: user?.email || "",
//       mobile: user?.mobileno || "",
//     },
//     enableReinitialize: true,
//     validationSchema,
//     onSubmit: async (values) => {
//       await sendToBackend(values);
//     },
//   });

//   const sendToBackend = async (datas) => {
//     try {
//       const userId = localStorage.getItem("userId");

//       const headers = {
//         Authorization: `Bearer ${userId}`,
//         "Content-Type": "application/json",
//       };
//       const formData = new FormData();
//       formData.append("name", datas?.name);
//       formData.append("email", datas?.email);
//       formData.append("mobile", datas?.mobile);

//       const response = await axios.put(
//         `${API_BASE_URL}/updateprofile`,
//         formData,
//         {
//           headers,
//         }
//       );

//       Swal.fire({
//         icon: "success",
//         title: "Profile Updated successfully!",
//         text: response.data.message,
//         showConfirmButton: false,
//         timer: 5000,
//       });

//       axios
//         .get(`${API_BASE_URL}/myprofile`, { headers })
//         .then((result) => {
//           setUser(result?.data?.user); // Update user state with the new data
//         })
//         .catch((error) => {
//           console.log("error on get profile", error.response.status);
//         });

//       handleClose();
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: error.response.data.message,
//         timer: 3000,
//       });
//     } finally {
//       formik.resetForm();
//     }
//   };

//   const renderContent = () => {
//     switch (activeContent) {
//       case "profile":
//         return (
//           <motion.div
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             transition={{ duration: 1 }}
//           >
//             <section className="profile-page-container ">
//               {user ? (
//                 <>
//                   <section className="d-flex flex-column align-item-center justify-content-center profile-view-page-container container">
//                     {/* <section className="d-flex flex-column align-item-center justify-content-center profile-view-page-container container"> */}
//                     <div
//                       className="profile_image_container"
//                       style={{ textAlign: "center" }}
//                     >
//                       <img
//                         src={user_image}
//                         alt="user_image"
//                         className="navigation-user-image"
//                         style={{ borderRadius: "50%" }}
//                       />
//                       <h2 style={{ fontFamily: "sans-serif" }}>{user.name}</h2>
//                       {/* <button
//                       style={{ backgroundColor: "transparent", border: "none" }}
//                       onClick={handleOpen}
//                     >
//                       <BsPen /> Edit Profile
//                     </button> */}
//                     </div>
//                     {/* <section className="d-flex align-item-center justify-content-between mt-3 ">
//                     <h4 style={{ marginLeft: "2px" }}>About</h4>
//                     <span
//                       className="edit-profile-change-password-containers-btn"
//                       style={{ display: "inline" }}
//                     >
//                       <button
//                         style={{
//                           backgroundColor: "transparent",
//                           border: "none",
//                           textDecoration: "underline",
//                         }}
//                         onClick={handleOpen}
//                       >
//                         <BsPen /> Edit Profile
//                       </button>{" "}
//                       & &nbsp;
//                       <button
//                         style={{
//                           backgroundColor: "transparent",
//                           border: "none",
//                           textDecoration: "underline",
//                         }}
//                         onClick={handleOpenPasswordContainer}
//                       >
//                         Chane password
//                       </button>
//                     </span>
//                   </section>

//                   <Form className="profile-form">
//                   <Row>
//                      <Col md={5} className="text-right">
//                     <label htmlFor="" style={{textAlign:"right"}}>
//                       Full Name:
//                     </label>
//                      </Col>
//                      <Col md={7}>
//                     <input type="text"
//                     className="form-control"
//                     value={user.name}
//                     />
//                     </Col>
//                     </Row>


//                     <Form.Group as={Row} className="mb-3">
//                       <Form.Label column sm="2">
//                         Email
//                       </Form.Label>
//                       <Col sm="10">
//                         <Form.Control readOnly value={user.email} />
//                       </Col>
//                     </Form.Group>

//                     <Form.Group as={Row} className="mb-3">
//                       <Form.Label column sm="2">
//                         Mobile No
//                       </Form.Label>
//                       <Col sm="10">
//                         <Form.Control
//                           type="tel"
//                           readOnly
//                           value={user.mobileno}
//                         />
//                       </Col>
//                     </Form.Group>
//                   </Form> */}
//                     <Container className="d-flex justify-content-center align-items-center">
//                       <Form className="w-100 m-auto">
//                         <Row className="justify-content-center mb-3">
//                           <Col
//                             md={6}
//                             lg={4}
//                             className="d-flex align-items-center"
//                           >
//                             <label className="me-2">Name:</label>
//                             <input
//                               type="text"
//                               className="form-control border-1"
//                               value={user.name}
//                               readOnly
//                             />
//                           </Col>
//                         </Row>
//                         <Row className="justify-content-center mb-3">
//                           <Col
//                             md={6}
//                             lg={4}
//                             className="d-flex align-items-center"
//                           >
//                             <label className="me-2">Email:</label>
//                             <input
//                               type="text"
//                               className="form-control border-1 "
//                               value={user.email}
//                               readOnly
//                             />
//                           </Col>
//                         </Row>
//                         <Row className="justify-content-center mb-3">
//                           <Col
//                             md={6}
//                             lg={4}
//                             className="d-flex align-items-center"
//                           >
//                             <label className="me-2">Phone:</label>
//                             <input
//                               type="text"
//                               className="form-control border-1 "
//                               value={user.mobileno}
//                               readOnly
//                             />
//                           </Col>
//                         </Row>
//                       </Form>
//                     </Container>
//                     <section className="d-flex align-item-center justify-content-center mt-3 dsdsdsds ">
//                       {/* <h4 style={{ marginLeft: "2px" }}>About</h4> */}
//                       <span
//                         className="edit-profile-change-password-containers-btn"
//                         style={{ display: "inline" }}
//                       >
//                         <button
//                           className="btn btn-primary "
//                           onClick={handleOpen}
//                         >
//                           <BsPen /> Edit Profile
//                         </button>{" "}
//                         {/* & &nbsp; */}
//                         <button
//                           className="btn btn-danger ms-2"
//                           style={{}}
//                           onClick={handleOpenPasswordContainer}
//                         >
//                           Chane password
//                         </button>
//                       </span>
//                     </section>
//                   </section>

//                   <Modal
//                     open={editProfilePage}
//                     onClose={handleClose}
//                     aria-labelledby="modal-modal-title"
//                     aria-describedby="modal-modal-description"
//                   >
//                     <motion.div
//                       className="modal-box1"
//                       initial={{ opacity: 0, scale: 0.5 }}
//                       animate={{ opacity: 1, scale: 1 }}
//                       transition={{
//                         duration: 0.3,
//                         type: "spring",
//                         damping: 5,
//                         stiffness: 100,
//                       }}
//                     >
//                       <Box className="modal-box">
//                         <Container className="col-sm-6 mt-5 edit-profile-page-container">
//                           <Form
//                             onSubmit={formik.handleSubmit}
//                             className="edit-profile-page-form"
//                           >
//                             <h1>Edit Profile</h1>
//                             <Row>
//                               <Col md={12}>
//                                 <Form.Group controlId="formName">
//                                   <Form.Label>Name:</Form.Label>
//                                   <TextField
//                                     {...formik.getFieldProps("name")}
//                                     placeholder="Enter your name"
//                                     fullWidth
//                                     error={
//                                       formik.touched.name &&
//                                       Boolean(formik.errors.name)
//                                     }
//                                     helperText={
//                                       formik.touched.name && formik.errors.name
//                                     }
//                                     value={formik.values.name} // Use formik values
//                                   />
//                                 </Form.Group>
//                               </Col>
//                               <Col md={12}>
//                                 <Form.Group controlId="formEmail">
//                                   <Form.Label>Email:</Form.Label>
//                                   <TextField
//                                     {...formik.getFieldProps("email")}
//                                     placeholder="Enter your email"
//                                     fullWidth
//                                     error={
//                                       formik.touched.email &&
//                                       Boolean(formik.errors.email)
//                                     }
//                                     helperText={
//                                       formik.touched.email &&
//                                       formik.errors.email
//                                     }
//                                     value={formik.values.email} // Use formik values
//                                   />
//                                 </Form.Group>
//                               </Col>
//                               <Col md={12}>
//                                 <Form.Group controlId="formMobile">
//                                   <Form.Label>Mobile:</Form.Label>
//                                   <TextField
//                                     {...formik.getFieldProps("mobile")}
//                                     placeholder="Enter your mobile number"
//                                     fullWidth
//                                     error={
//                                       formik.touched.mobile &&
//                                       Boolean(formik.errors.mobile)
//                                     }
//                                     helperText={
//                                       formik.touched.mobile &&
//                                       formik.errors.mobile
//                                     }
//                                     value={formik.values.mobile} // Use formik values
//                                   />
//                                 </Form.Group>
//                               </Col>
//                               <Col
//                                 md={12}
//                                 className="edit-profile-page-btn-container"
//                               >
//                                 <Button
//                                   type="submit"
//                                   className="edit-profile-page-btn"
//                                   style={{
//                                     backgroundColor: "blue",
//                                     color: "white",
//                                     marginTop: "20px",
//                                     textAlign: "center",
//                                   }}
//                                 >
//                                   Update
//                                 </Button>
//                                 <Button
//                                   className="edit-profile-page-btn"
//                                   style={{
//                                     backgroundColor: "red",
//                                     color: "white",
//                                     marginTop: "20px",
//                                     textAlign: "center",
//                                   }}
//                                   onClick={handleClose}
//                                 >
//                                   Cancel
//                                 </Button>
//                               </Col>
//                             </Row>
//                           </Form>
//                         </Container>
//                       </Box>
//                     </motion.div>
//                   </Modal>

//                   <Modal
//                     open={showPasswordContainer}
//                     onClose={handleClosePasswordContainer}
//                     aria-labelledby="modal-modal-title"
//                     aria-describedby="modal-modal-description"
//                   >
//                     <motion.div
//                       className="modal-box1"
//                       initial={{ opacity: 0, scale: 0.5 }}
//                       animate={{ opacity: 1, scale: 1 }}
//                       transition={{
//                         duration: 0.3,
//                         type: "spring",
//                         damping: 5,
//                         stiffness: 100,
//                       }}
//                     >
//                       <Box className="modal-box">
//                         <Container className="col-sm-6 mt-5 edit-Password-container">
//                           <Form
//                             onSubmit={formik.handleSubmit}
//                             className="edit-password"
//                           >
//                             <h1>Update Password</h1>
//                             <Row>
//                               <Col md={12}>
//                                 <Form.Group controlId="formPassword">
//                                   <Form.Label>Old Password:</Form.Label>
//                                   <TextField
//                                     placeholder="Enter your Old Password"
//                                     fullWidth
//                                     type="text"
//                                     value={currentPassword}
//                                     onChange={(e) =>
//                                       setCurrentPassword(e.target.value)
//                                     }
//                                   />
//                                 </Form.Group>
//                               </Col>
//                               <Col md={12}>
//                                 <Form.Group controlId="formNewPassword">
//                                   <Form.Label style={{ display: "block" }}>
//                                     New Password:
//                                   </Form.Label>
//                                   <TextField
//                                     placeholder="Enter your New Password"
//                                     type="password"
//                                     value={newPassword}
//                                     onChange={(e) =>
//                                       setNewPassword(e.target.value)
//                                     }
//                                     style={{ display: "grid" }}
//                                   />
//                                 </Form.Group>
//                               </Col>
//                               <Col md={12}>
//                                 <Form.Group controlId="formConfirmPassword">
//                                   <Form.Label className="w-100">
//                                     Confirm Password:
//                                   </Form.Label>
//                                   <TextField
//                                     // placeholder="Enter your mobile number"
//                                     type={hide ? "text" : "password"}
//                                     value={confirmPassword}
//                                     onChange={(e) =>
//                                       setConfirmPassword(e.target.value)
//                                     }
//                                     style={{ width: "100%" }}
//                                   />
//                                   <button
//                                     type="button"
//                                     onClick={() => setHide(!hide)}
//                                     style={{
//                                       backgroundColor: "transparent",
//                                       border: "none",
//                                       position: "relative",
//                                       bottom: "40px",
//                                       left: "93%",
//                                     }}
//                                   >
//                                     {hide ? (
//                                       <FaEye className="eye-icon" />
//                                     ) : (
//                                       <FaEyeSlash className="eye-icon" />
//                                     )}
//                                   </button>
//                                 </Form.Group>
//                               </Col>
//                               {passwordError && (
//                                 <div className="error" style={{ color: "red" }}>
//                                   {passwordError}
//                                 </div>
//                               )}
//                               <Col
//                                 md={12}
//                                 className="edit-profile-page-btn-container"
//                               >
//                                 <Button
//                                   // type="submit"
//                                   className="edit-profile-page-btn"
//                                   style={{
//                                     backgroundColor: "blue",
//                                     color: "white",
//                                     marginTop: "10px",
//                                     textAlign: "center",
//                                   }}
//                                   onClick={handleChangePassword}
//                                 >
//                                   Submit
//                                 </Button>
//                                 <Button
//                                   className="edit-profile-page-btn"
//                                   style={{
//                                     backgroundColor: "red",
//                                     color: "white",
//                                     marginTop: "10px",
//                                     textAlign: "center",
//                                   }}
//                                   onClick={handleClosePasswordContainer}
//                                 >
//                                   Cancel
//                                 </Button>
//                               </Col>
//                             </Row>
//                           </Form>
//                         </Container>
//                       </Box>
//                     </motion.div>
//                   </Modal>
//                 </>
//               ) : (
//                 <div>Loading...</div>
//               )}
//             </section>
//           </motion.div>
//         );
//       case "dashboard":
//         return <DashBoard />;
//       case "orders":
//         return <OrderHistory />;
//       case "inquiry":
//         return (
//           // <div>
//           //   <h2>My Inquiries</h2>
//           //   <p>Not available</p>
//           // </div>
//           <Inquiry/>
//         );
//       case "address":
//         return (
//           <div>
//             <h2>Address</h2>
//             <div className="container-fluid">
//               <div className="table-responsive">
//                 <table className="table table-striped table-bordered">
//                   <thead className="thead-dark">
//                     <tr>
//                       <th scope="col">S.No</th>
//                       <th scope="col" className="nowrap">
//                         Street
//                       </th>
//                       <th scope="col" className="nowrap">
//                         District
//                       </th>
//                       <th scope="col" className="nowrap">
//                         State
//                       </th>
//                       <th scope="col" className="nowrap">
//                         Country
//                       </th>
//                       <th scope="col" className="nowrap">
//                         Pincode
//                       </th>
//                     </tr>
//                   </thead>
//                   {user?.address?.length === 0 ? (
//                     <tbody>
//                       <tr>
//                         <td colSpan="6" className="text-center text-danger">
//                           Link the address
//                         </td>
//                       </tr>
//                     </tbody>
//                   ) : (
//                     <tbody>
//                       {user?.address?.map((address, index) => (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td className="nowrap">{address.street}</td>
//                           <td className="nowrap">{address.district}</td>
//                           <td className="nowrap">{address.state}</td>
//                           <td className="nowrap">{address.country} </td>
//                           <td className="nowrap">{address.pincode}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   )}
//                 </table>
//               </div>
//             </div>
//           </div>
//         );
//       case "account":
//         return (
//           <div>
//             <h2>Account Details</h2>
//             <p>Please link the account</p>
//           </div>
//         );

//       default:
//         return <div>Content not available</div>;
//     }
//   };

//   const renderSidebar = () => (
//     <ListGroup variant="flush" className="sidebar">
//       <ListGroup.Item action onClick={() => setActiveContent("profile")}>
//         Profile
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("dashboard")}>
//         Dashboard
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("orders")}>
//         My Orders
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("inquiry")}>
//         Inquiry
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("address")}>
//         Address
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("account")}>
//         Account Details
//       </ListGroup.Item>
//     </ListGroup>
//   );

//   const renderAccordion = () => (
//     <section className="profile-dashboard">
//       <Accordion defaultActiveKey="0">
//         <Accordion.Item eventKey="0">
//           <Accordion.Header
//             onClick={() => setActiveContent("profile")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Profile
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "profile" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="1">
//           <Accordion.Header
//             onClick={() => setActiveContent("dashboard")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Dashboard
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "dashboard" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="2">
//           <Accordion.Header
//             onClick={() => setActiveContent("orders")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             My Orders
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "orders" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="3">
//           <Accordion.Header
//             onClick={() => setActiveContent("inquiry")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Inquiry
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "inquiry" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="4">
//           <Accordion.Header
//             onClick={() => setActiveContent("address")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Addresses
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "address" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="5">
//           <Accordion.Header
//             onClick={() => setActiveContent("account")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Account details
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "account" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//       </Accordion>
//     </section>
//   );

//   return (
//     <div className="dash">
//       <Container fluid>
//         <Row>
//           <Col lg={12}>
//             {isAccordion ? renderAccordion() : renderAccordion()}
//             <Button
//               // variant="secondary"
//               className="logout-btn"
//               onClick={handleLogout}
//             >
//               Log out
//             </Button>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default Profile;

// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================
// // ===========================================================================================================================================================





import { BsPen } from "react-icons/bs";
import * as React from "react";
import user_image from "../../Assets/Images/user-image-four.png";
import { useContext, useEffect, useState } from "react";
import "../Profile/Profile.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import userContext from "../../components/UseContext/UseContext";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Button from "@mui/material/Button";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  Form,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./DashBoard.css";
import { motion } from "framer-motion";
import { TextField, Box, Modal } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Profile.scss";
import DashBoard from "./DashBoard/DashBoard";
import OrderHistory from "./Order/OrderHistory";
import Inquiry from "./Inquiry/Inquiry";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Profile() {
  const [user, setUser] = useState();
  const [logOut, setLogOut] = useState(false);
  const navigate = useNavigate();
  const ProfileUseContext = useContext(userContext);
  const refreshToken = localStorage.getItem("refreshToken");

  const [activeContent, setActiveContent] = useState("profile");
  const [isAccordion, setIsAccordion] = useState(window.innerWidth >= 992);
  const [editProfilePage, setEditProfilePage] = useState(false);
  const [showPasswordContainer, setShowPasswordContainer] = useState(false);

  const [hide, setHide] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const isLoggedIn = localStorage.getItem("userId");

  useEffect(() => {
    const handleResize = () => {
      setIsAccordion(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      navigate("/");
      return;
    }

    const headers = {
      Authorization: `Bearer ${userId}`,
      "Content-Type": "application/json",
    };

    // console.log("header are come in profile page",headers)
    axios
      .get(`${API_BASE_URL}/myprofile`, { headers })
      .then((result) => {
        setUser(result?.data?.user);
      })
      .catch((error) => {
        // console.log("error on get profile", error.response.status);
        if (error.response.status === 401) {
          localStorage.setItem("userId", "");
          navigate("/login");
        }
      });
  }, [logOut, ProfileUseContext.profileActive, refreshToken]);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("refreshToken");
    setLogOut(true);
  };

  const handleOpen = () => setEditProfilePage(true);
  const handleClose = () => setEditProfilePage(false);

  const handleOpenPasswordContainer = () => setShowPasswordContainer(true);
  const handleClosePasswordContainer = () => {
    resetPasswordFields();
    setShowPasswordContainer(false);
  };
  const resetPasswordFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };

  // const handleChangePassword = async () => {
  //   if (currentPassword && newPassword && confirmPassword) {
  //     if (newPassword !== confirmPassword) {
  //       setPasswordError("New password and confirm password do not match.");
  //       return;
  //     }
  //     try {
  //       const userId = localStorage.getItem("userId");
  //       const headers = {
  //         Authorization: `Bearer ${userId}`,
  //         "Content-Type": "application/json",
  //       };
  //       const updatePasswordResponse = await axios.put(
  //         `${API_BASE_URL}/password/change`,
  //         {
  //           currentPassword: currentPassword,
  //           newPassword: newPassword,
  //         },
  //         { headers }
  //       );
  //       console.log("Password updated:", updatePasswordResponse.data);
  //       Swal.fire({
  //         icon: "success",
  //         title: "Password Changed Successfully",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //       resetPasswordFields();
  //       handleClose();
  //     } catch (error) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: error.response.data.error,
  //       });

  //       // console.error("Error updating password:", error.response.data.error);
  //     }
  //   }
  // };

  const handleChangePassword = async () => {
    if (currentPassword && newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        setPasswordError("New password and confirm password do not match.");
        return;
      }

      try {
        const userId = localStorage.getItem("userId");
        const headers = {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "application/json",
        };

        const updatePasswordResponse = await axios.put(
          `${API_BASE_URL}/password/change`,
          {
            currentPassword,
            newPassword,
          },
          { headers:headers }
        );

        // console.log("Password updated:", updatePasswordResponse.data);
        Swal.fire({
          icon: "success",
          title: "Password Changed Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        resetPasswordFields();
        handleClosePasswordContainer();
      } catch (error) {
        if (true) {
          setPasswordError(`${error.response.data.error}`);
          return;
        }
        console.error("Error updating password:", error.response.data.error);
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid email address"
      )
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      email: user?.email || "",
      mobile: user?.mobileno || "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      await sendToBackend(values);
    },
  });

  const sendToBackend = async (datas) => {
    try {
      const userId = localStorage.getItem("userId");

      const headers = {
        Authorization: `Bearer ${userId}`,
        "Content-Type": "application/json",
      };
      const formData = new FormData();
      formData.append("name", datas?.name);
      formData.append("email", datas?.email);
      formData.append("mobile", datas?.mobile);

      const response = await axios.put(
        `${API_BASE_URL}/updateprofile`,
        formData,
        {
          headers:headers,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Profile Updated successfully!",
        text: response.data.message,
        showConfirmButton: false,
        timer: 5000,
      });

      axios
        .get(`${API_BASE_URL}/myprofile`, { headers })
        .then((result) => {
          setUser(result?.data?.user); // Update user state with the new data
        })
        .catch((error) => {
          console.log("error on get profile", error.response.status);
        });

      handleClose();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
        timer: 3000,
      });
    } finally {
      formik.resetForm();
    }
  };

  const renderContent = () => {
    switch (activeContent) {
      case "profile":
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <section className="profile-page-container ">
              {user ? (
                <>
                  <section className="d-flex flex-column align-item-center justify-content-center profile-view-page-container container">
                    {/* <section className="d-flex flex-column align-item-center justify-content-center profile-view-page-container container"> */}
                    <div
                      className="profile_image_container"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src={user_image}
                        alt="user_image"
                        className="navigation-user-image"
                        style={{ borderRadius: "50%" }}
                      />
                      <h2 style={{ fontFamily: "sans-serif" }}>{user.name}</h2>
                      {/* <button
                      style={{ backgroundColor: "transparent", border: "none" }}
                      onClick={handleOpen}
                    >
                      <BsPen /> Edit Profile
                    </button> */}
                    </div>
                    {/* <section className="d-flex align-item-center justify-content-between mt-3 ">
                    <h4 style={{ marginLeft: "2px" }}>About</h4>
                    <span
                      className="edit-profile-change-password-containers-btn"
                      style={{ display: "inline" }}
                    >
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          textDecoration: "underline",
                        }}
                        onClick={handleOpen}
                      >
                        <BsPen /> Edit Profile
                      </button>{" "}
                      & &nbsp;
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          textDecoration: "underline",
                        }}
                        onClick={handleOpenPasswordContainer}
                      >
                        Chane password
                      </button>
                    </span>
                  </section>

                  <Form className="profile-form">
                  <Row>
                     <Col md={5} className="text-right">
                    <label htmlFor="" style={{textAlign:"right"}}>
                      Full Name:
                    </label>
                     </Col>
                     <Col md={7}>
                    <input type="text"
                    className="form-control"
                    value={user.name}
                    />
                    </Col>
                    </Row>


                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Email
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control readOnly value={user.email} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Mobile No
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="tel"
                          readOnly
                          value={user.mobileno}
                        />
                      </Col>
                    </Form.Group>
                  </Form> */}
                    <Container className="d-flex justify-content-center align-items-center">
                      <Form className="w-100 m-auto">
                        <Row className="justify-content-center mb-3">
                          <Col
                            md={6}
                            lg={4}
                            className="d-flex align-items-center"
                          >
                            <label className="me-2">Name:</label>
                            <input
                              type="text"
                              className="form-control border-1"
                              value={user.name}
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-center mb-3">
                          <Col
                            md={6}
                            lg={4}
                            className="d-flex align-items-center"
                          >
                            <label className="me-2">Email:</label>
                            <input
                              type="text"
                              className="form-control border-1 "
                              value={user.email}
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-center mb-3">
                          <Col
                            md={6}
                            lg={4}
                            className="d-flex align-items-center"
                          >
                            <label className="me-2">Phone:</label>
                            <input
                              type="text"
                              className="form-control border-1 "
                              value={user.mobileno}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Container>
                    <section className="d-flex align-item-center justify-content-center mt-3 dsdsdsds ">
                      {/* <h4 style={{ marginLeft: "2px" }}>About</h4> */}
                      <span
                        className="edit-profile-change-password-containers-btn"
                        style={{ display: "inline" }}
                      >
                        <button
                          className="btn btn-primary "
                          onClick={handleOpen}
                        >
                          <BsPen /> Edit Profile
                        </button>{" "}
                        {/* & &nbsp; */}
                        <button
                          className="btn btn-danger ms-2"
                          style={{}}
                          onClick={handleOpenPasswordContainer}
                        >
                          Chane password
                        </button>
                      </span>
                    </section>
                  </section>

                  <Modal
                    open={editProfilePage}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <motion.div
                      className="modal-box1"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.3,
                        type: "spring",
                        damping: 5,
                        stiffness: 100,
                      }}
                    >
                      <Box className="modal-box">
                        <Container className="col-sm-6 mt-5 edit-profile-page-container">
                          <Form
                            onSubmit={formik.handleSubmit}
                            className="edit-profile-page-form"
                          >
                            <h1>Edit Profile</h1>
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="formName">
                                  <Form.Label>Name:</Form.Label>
                                  <TextField
                                    {...formik.getFieldProps("name")}
                                    placeholder="Enter your name"
                                    fullWidth
                                    error={
                                      formik.touched.name &&
                                      Boolean(formik.errors.name)
                                    }
                                    helperText={
                                      formik.touched.name && formik.errors.name
                                    }
                                    value={formik.values.name} // Use formik values
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group controlId="formEmail">
                                  <Form.Label>Email:</Form.Label>
                                  <TextField
                                    {...formik.getFieldProps("email")}
                                    placeholder="Enter your email"
                                    fullWidth
                                    error={
                                      formik.touched.email &&
                                      Boolean(formik.errors.email)
                                    }
                                    helperText={
                                      formik.touched.email &&
                                      formik.errors.email
                                    }
                                    value={formik.values.email} // Use formik values
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group controlId="formMobile">
                                  <Form.Label>Mobile:</Form.Label>
                                  <TextField
                                    {...formik.getFieldProps("mobile")}
                                    placeholder="Enter your mobile number"
                                    fullWidth
                                    error={
                                      formik.touched.mobile &&
                                      Boolean(formik.errors.mobile)
                                    }
                                    helperText={
                                      formik.touched.mobile &&
                                      formik.errors.mobile
                                    }
                                    value={formik.values.mobile} // Use formik values
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                md={12}
                                className="edit-profile-page-btn-container"
                              >
                                <Button
                                  type="submit"
                                  className="edit-profile-page-btn"
                                  style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  Update
                                </Button>
                                <Button
                                  className="edit-profile-page-btn"
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Container>
                      </Box>
                    </motion.div>
                  </Modal>

                  <Modal
                    open={showPasswordContainer}
                    onClose={handleClosePasswordContainer}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <motion.div
                      className="modal-box1"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.3,
                        type: "spring",
                        damping: 5,
                        stiffness: 100,
                      }}
                    >
                      <Box className="modal-box">
                        <Container className="col-sm-6 mt-5 edit-Password-container">
                          <Form
                            onSubmit={formik.handleSubmit}
                            className="edit-password"
                          >
                            <h1>Update Password</h1>
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="formPassword">
                                  <Form.Label>Old Password:</Form.Label>
                                  <TextField
                                    placeholder="Enter your Old Password"
                                    fullWidth
                                    type="text"
                                    value={currentPassword}
                                    onChange={(e) =>
                                      setCurrentPassword(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group controlId="formNewPassword">
                                  <Form.Label style={{ display: "block" }}>
                                    New Password:
                                  </Form.Label>
                                  <TextField
                                    placeholder="Enter your New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                    style={{ display: "grid" }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group controlId="formConfirmPassword">
                                  <Form.Label className="w-100">
                                    Confirm Password:
                                  </Form.Label>
                                  <TextField
                                    // placeholder="Enter your mobile number"
                                    type={hide ? "text" : "password"}
                                    value={confirmPassword}
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                    style={{ width: "100%" }}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => setHide(!hide)}
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      position: "relative",
                                      bottom: "40px",
                                      left: "93%",
                                    }}
                                  >
                                    {hide ? (
                                      <FaEye className="eye-icon" />
                                    ) : (
                                      <FaEyeSlash className="eye-icon" />
                                    )}
                                  </button>
                                </Form.Group>
                              </Col>
                              {passwordError && (
                                <div className="error" style={{ color: "red" }}>
                                  {passwordError}
                                </div>
                              )}
                              <Col
                                md={12}
                                className="edit-profile-page-btn-container"
                              >
                                <Button
                                  // type="submit"
                                  className="edit-profile-page-btn"
                                  style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                    marginTop: "10px",
                                    textAlign: "center",
                                  }}
                                  onClick={handleChangePassword}
                                >
                                  Submit
                                </Button>
                                <Button
                                  className="edit-profile-page-btn"
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    marginTop: "10px",
                                    textAlign: "center",
                                  }}
                                  onClick={handleClosePasswordContainer}
                                >
                                  Cancel
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Container>
                      </Box>
                    </motion.div>
                  </Modal>
                </>
              ) : (
                <div>Loading...</div>
              )}
            </section>
          </motion.div>
        );
      case "dashboard":
        return <DashBoard />;
      case "orders":
        return <OrderHistory />;
      case "inquiry":
        return (
          // <div>
          //   <h2>My Inquiries</h2>
          //   <p>Not available</p>
          // </div>
          <Inquiry/>
        );
      case "address":
        return (
          <div>
            <h2>Address</h2>
            <div className="container-fluid">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col" className="nowrap">
                        Street
                      </th>
                      <th scope="col" className="nowrap">
                        District
                      </th>
                      <th scope="col" className="nowrap">
                        State
                      </th>
                      <th scope="col" className="nowrap">
                        Country
                      </th>
                      <th scope="col" className="nowrap">
                        Pincode
                      </th>
                    </tr>
                  </thead>
                  {user?.address?.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="6" className="text-center text-danger">
                          Link the address
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {user?.address?.map((address, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="nowrap">{address.street}</td>
                          <td className="nowrap">{address.district}</td>
                          <td className="nowrap">{address.state}</td>
                          <td className="nowrap">{address.country} </td>
                          <td className="nowrap">{address.pincode}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        );
      case "account":
        return (
          <div>
            <h2>Account Details</h2>
            <p>Please link the account</p>
          </div>
        );

      default:
        return <div>Content not available</div>;
    }
  };

//   const renderSidebar = () => (
//     <ListGroup variant="flush" className="sidebar">
//       <ListGroup.Item action onClick={() => setActiveContent("profile")}>
//         Profile
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("dashboard")}>
//         Dashboard
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("orders")}>
//         My Orders
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("inquiry")}>
//         Inquiry
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("address")}>
//         Address
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => setActiveContent("account")}>
//         Account Details
//       </ListGroup.Item>
//     </ListGroup>
//   );

//   const renderAccordion = () => (
//     <section className="profile-dashboard">

// <Tabs
//       defaultActiveKey="profile"
//       id="justify-tab-example"
//       className="mb-3"
//       justify
//     >
//       <Tab eventKey="home" title="Profile "   onClick={() => setActiveContent("profile")}>
//       {activeContent === "profile" && renderContent()}
//       </Tab>
//       <Tab eventKey="Dashboard" title="Dashboard"     onClick={() => setActiveContent("dashboard")}>
//       {activeContent === "dashboard" && renderContent()}
       
//       </Tab>
//       <Tab eventKey="My Orders" title=" My Orders">
//       {activeContent === "orders" && renderContent()}
//       </Tab>
//       <Tab eventKey="Inquiry" title="Inquiry">
//       {activeContent === "inquiry" && renderContent()}
//       </Tab>
//       <Tab eventKey="Addresses" title="Addresses">
//       {activeContent === "address" && renderContent()}
//       </Tab>
//       <Tab eventKey="Account details" title="Account details">
//       {activeContent === "account" && renderContent()}
//       </Tab>
     
//     </Tabs>





//       {/* <Accordion defaultActiveKey="0">
//         <Accordion.Item eventKey="0">
//           <Accordion.Header
//             onClick={() => setActiveContent("profile")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Profile
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "profile" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="1">
//           <Accordion.Header
//             onClick={() => setActiveContent("dashboard")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Dashboard
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "dashboard" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="2">
//           <Accordion.Header
//             onClick={() => setActiveContent("orders")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             My Orders
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "orders" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="3">
//           <Accordion.Header
//             onClick={() => setActiveContent("inquiry")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Inquiry
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "inquiry" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="4">
//           <Accordion.Header
//             onClick={() => setActiveContent("address")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Addresses
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "address" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="5">
//           <Accordion.Header
//             onClick={() => setActiveContent("account")}
//             style={{
//               fontSize: "18px",
//               fontWeight: "600",
//               letterSpacing: "1px",
//             }}
//           >
//             Account details
//           </Accordion.Header>
//           <Accordion.Body>
//             {activeContent === "account" && renderContent()}
//           </Accordion.Body>
//         </Accordion.Item>
//       </Accordion> */}
//     </section>
//   );


const renderTabs = () => (
  <section className="profile-dashboard mt-3">
  <Tabs
    defaultActiveKey="profile"
    id="justify-tab-example"
    className="mb-3 custom-tabs"
    justify
    onSelect={(k) => setActiveContent(k)}
  >
    <Tab eventKey="profile" title={<span className="custom-tab-title text-black">Profile</span>}>
      {activeContent === "profile" && renderContent()}
    </Tab>
    <Tab eventKey="dashboard" title={<span className="custom-tab-title text-black">Dashboard</span>}>
      {activeContent === "dashboard" && renderContent()}
    </Tab>
    <Tab eventKey="orders" title={<span className="custom-tab-title text-black">My Orders</span>}>
      {activeContent === "orders" && renderContent()}
    </Tab>
    <Tab eventKey="inquiry" title={<span className="custom-tab-title text-black">Inquiry</span>}>
      {activeContent === "inquiry" && renderContent()}
    </Tab>
    <Tab eventKey="address" title={<span className="custom-tab-title text-black">Addresses</span>}>
      {activeContent === "address" && renderContent()}
    </Tab>
    <Tab eventKey="account" title={<span className="custom-tab-title text-black">Account Details</span>}>
      {activeContent === "account" && renderContent()}
    </Tab>
  </Tabs>
</section>

);

return (
  <div className="dash">
    <Container fluid>
      <Row>
        <Col lg={12}>
          {renderTabs()}
          {/* <Button
            className="logout-btn"
            onClick={handleLogout}
          >
            Log out
          </Button> */}
        </Col>
      </Row>
    </Container>
  </div>
);
}

export default Profile;

// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================
// ===========================================================================================================================================================

