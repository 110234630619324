// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import { API_BASE_URL } from "../../utils/index";
// import WishlistButton from "../Buttons/WishlistButton";
// import ProductOveriew from "../ProductOveriew/ProductOveriew";
// import AddCartButton from "../Buttons/AddCartButton";
// import ProductImages from "../Products/ProductImages/ProductImages";
// import { IoCaretBack } from "react-icons/io5";
// import Lottie from "lottie-react";
// import NoProductFound from "../../Preloader/No product Found Animation.json";

// function SubCategory() {
//   const { subcategoryName } = useParams();
//   const [subCategories, setSubCategories] = useState([]);
//   const [subCategoriesName, setSubCategoriesName] = useState([]);

//   useEffect(() => {
//     axios
//       .get(
//         `${API_BASE_URL}/user/product/getprodutbysubcategory/${subcategoryName}`
//       )
//       .then((response) => {
//         setSubCategories(response?.data?.products);
//         setSubCategoriesName(response?.data?.subcategory?.name);
//         console.log("response getprodutbysubcategory :", response?.data);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, []);

//   return (
//     <div className="row px-2r m-0 products-wrapper ">
//       <center className="products-heading-container my-4">
//         <h4 className="product-heading">{subCategoriesName}</h4>
//         <Link to="/" className="view-all-products">
//           <IoCaretBack className="fs-5" />
//           Go Back
//         </Link>
//       </center>

//       {subCategories?.length !== 0 ? (
//         <div className=" products m-0">
//           {subCategories?.map((product, i) => {
//             return (
//               <div className="card" key={i}>
//                 <center>
//                   <Card.Title className="px-2 text-dark card-title">
//                     <Link
//                       className="text-dark text-decoration-none"
//                       to={`/part-number-page/${
//                         product?.descriptions &&
//                         product?.descriptions[0] &&
//                         product?.descriptions[0]?.value
//                       }`}
//                     >
//                       {product?.partNumber}
//                     </Link>
//                   </Card.Title>
//                 </center>

//                 <Link
//                   to={`/part-number-page/${
//                     product?.descriptions &&
//                     product?.descriptions[0] &&
//                     product?.descriptions[0]?.value
//                   }`}
//                 >
//                   <ProductImages productimages={product?.productimages} />
//                 </Link>

//                 <center className="cart-footer">
//                   <Link
//                     to={`/part-number-page/${
//                       product?.descriptions &&
//                       product?.descriptions[0] &&
//                       product?.descriptions[0]?.value
//                     }`}
//                     className="card-price text-decoration-none"
//                   >
//                     <h6 className="m-0 current-price">₹ {product?.saleprice}</h6>
//                     <p className="m-0 old-price">₹ {product?.oldprice}</p>
//                   </Link>

//                   <AddCartButton
//                     type="product_button"
//                     className="card-add-cart-button"
//                     data={product}
//                   />
//                 </center>

//                 <div className="card-content text-danger">
//                   <WishlistButton type="icon" data={product} />
//                   <div className="add-to-card">
//                     <ProductOveriew product={product} />
//                   </div>
//                   {/* <AddCartButton
//                     type="icon"
//                     className="bg-primary"
//                     data={product}
//                   /> */}
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       ) : (
//         // "No Products..."
//           <Lottie
//             style={{ width: "100vw", height: "50vh" }}
//             animationData={NoProductFound}
//           />

//       )}
//     </div>
//   );
// }

// export default SubCategory;

// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import { API_BASE_URL } from "../../utils/index";
// import WishlistButton from "../Buttons/WishlistButton";
// import ProductOveriew from "../ProductOveriew/ProductOveriew";
// import AddCartButton from "../Buttons/AddCartButton";
// import ProductImages from "../Products/ProductImages/ProductImages";
// import { IoCaretBack } from "react-icons/io5";
// import Lottie from "lottie-react";
// import NoProductFound from "../../Preloader/No product Found Animation.json";
// import Preloader from "../../Preloader/Preloader-Animation.json";
// import userContext from "../../components/UseContext/UseContext";

// function SubCategory() {
//   const { slug, params } = useParams();
//   console.log("subcategoryName", params);
//   console.log("categoryName", slug);
//   const [subCategories, setSubCategories] = useState([]);
//   const [subCategoriesName, setSubCategoriesName] = useState("");
//   const [loading, setLoading] = useState(true);
//   const subProducts = useContext(userContext);
//   const replaceHyphensWithSpaces = (urlString) => {
//     if (typeof urlString === 'string') {
//        return urlString.replace(/-/g, ' ');
//     }
//   };

//   console.log(params,"Subcat")

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const subcategoryName=replaceHyphensWithSpaces(params)
//         setLoading(true);
//         const response = await axios.get(
//           `${API_BASE_URL}/user/product/getprodutbysubcategory/${subcategoryName}`
//         );
//         setSubCategories(response?.data?.products);
//         subProducts.setSubProductDatas(response?.data?.products[0]?.category?.name)
//         // console.log("sub product history ------->",response?.data?.products[0]?.category?._id)
//         // console.log("sub product history ------->",response?.data?.products[0]?.category?._id)
//         console.log("response getprodutbysubcategory  ----------:", response?.data);
//         setSubCategoriesName(response?.data?.subcategory?.name);
//       } catch (error) {
//         console.log("error", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const replaceSpacesWithHyphens = (urlString) => {

//     if (typeof urlString === 'string') {
//       // Replace all spaces with hyphens
//       return urlString.replace(/ /g, '-');
//     }else return urlString;
// }

//   return (
//     <div className="row px-2r m-0 products-wrapper ">
//       <center className="products-heading-container my-4">
//         <h4 className="product-heading">{subCategoriesName}</h4>
//         <Link to="/" className="view-all-products">
//           <IoCaretBack className="fs-5" />
//           Go Back
//         </Link>
//       </center>

//       {loading ? (
//         <Lottie
//           style={{ width: "100vw", height: "50vh" }}
//           animationData={Preloader}
//         />
//       ) : (
//         <>
//           {subCategories?.length !== 0 ? (
//             <div className="products m-0">
//               {subCategories?.map((product, i) => (
//                 <div className="card" key={i}>
//                   <center>
//                     <Card.Title className="px-2 text-dark card-title">
//                       <Link
//                         className="text-dark text-decoration-none"
//                         // to={`/part-number-page/${
//                           to={product?.descriptions[0]?.value !== undefined ? `/${slug}/${replaceSpacesWithHyphens(subCategoriesName)}/${
//                             replaceSpacesWithHyphens(product?.descriptions &&
//                           product?.descriptions[0] &&
//                           product?.descriptions[0]?.value)
//                         }` : `/product/${product?.slug}`}
//                       >
//                         {product?.partNumber}
//                       </Link>
//                     </Card.Title>
//                   </center>

//                   <Link
//                     // to={`/part-number-page/${
//                     to={product?.descriptions[0]?.value !== undefined ?`/${slug}/${replaceSpacesWithHyphens(subCategoriesName)}/${
//                       replaceSpacesWithHyphens(product?.descriptions &&
//                       product?.descriptions[0] &&
//                       product?.descriptions[0]?.value)
//                     }` : `/product/${product?.slug}`}
//                   >
//                     <ProductImages productimages={product?.productimages} />
//                   </Link>

//                   <center className="cart-footer">
//                     <Link
//                       // to={`/part-number-page/${
//                         to={product?.descriptions[0]?.value !== undefined ? `/${slug}/${replaceSpacesWithHyphens(subCategoriesName)}/${
//                           replaceSpacesWithHyphens(product?.descriptions &&
//                         product?.descriptions[0] &&
//                         product?.descriptions[0]?.value)
//                       }` : `/product/${product?.slug}`}
//                       className="card-price text-decoration-none"
//                     >
//                       <h6 className="m-0 current-price">
//                         ₹ {product?.saleprice}
//                       </h6>
//                       <p className="m-0 old-price">₹ {product?.oldprice}</p>
//                     </Link>

//                     <AddCartButton
//                       type="product_button"
//                       className="card-add-cart-button"
//                       data={product}
//                     />
//                   </center>

//                   <div className="card-content text-danger">
//                     <WishlistButton type="icon" data={product} />
//                     <div className="add-to-card">
//                       <ProductOveriew product={product} />
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <Lottie
//               style={{ width: "100vw", height: "50vh" }}
//               animationData={NoProductFound}
//             />
//           )}
//         </>
//       )}
//     </div>
//   );
// }

// export default SubCategory;
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { API_BASE_URL } from "../../utils/index";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";
import AddCartButton from "../Buttons/AddCartButton";
import ProductImages from "../Products/ProductImages/ProductImages";
import { IoCaretBack } from "react-icons/io5";
import Lottie from "lottie-react";
import NoProductFound from "../../Preloader/No product Found Animation.json";
import Preloader from "../../Preloader/Preloader-Animation.json";
import userContext from "../../components/UseContext/UseContext";
import ViewSubCategorybydescriptions from "./ViewSubCategorybydescriptions";
import { Helmet } from "react-helmet";

function SubCategory() {
  const { slug, subcategoryName } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState("");
  const [loading, setLoading] = useState(true);
  const subProducts = useContext(userContext);
  const [sortedArray, setSortedArray] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subcategoryNameFetched = subcategoryName.includes("-")
          ? subcategoryName.replace(/-/g, " ").toUpperCase()
          : subcategoryName; 
        setLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/user/product/getprodutbysubcategory/${subcategoryNameFetched}`
        );
        console.log(response,"Sss")
        setAllProducts(response?.data.products);
        setSubCategories(response?.data?.products || []);
        subProducts.setSubProductDatas(
          response?.data?.products[0]?.category?.name
        );
        // console.log(
        //   "data fecthching--------> subcategory component",
        //   response?.data.products
        // );
        setSubCategoriesName(response?.data?.subcategory?.name || "");
      } catch (error) {
        console.error("Error fetching subcategory data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [subcategoryName, subProducts]);

  const replaceSpacesWithHyphens = (urlString) => {
    return typeof urlString === "string"
      ? urlString.replace(/ /g, "-").toLocaleLowerCase()
      : urlString;
  };
  useEffect(() => {
    if (allProducts.length > 0) {
      // Extract descriptions and remove duplicates
      const descriptions = allProducts.map(
        (product) => product.descriptions[0]?.value
      );
      const uniqueDescriptions = [...new Set(descriptions)];
      // console.log("--------->", uniqueDescriptions);
      setSortedArray(uniqueDescriptions);
    } else {
      setSortedArray([]); // Clear the sortedArray if there are no products
    }
  }, [allProducts]);
  
  // const capitalizeFirstLetter = (string) => {
  //   if (typeof string !== 'string') return '';
    
  //   const cleanedString = string.replace(/-/g, ' ');

  //   return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1);
  // };
  // const extractAndCapitalize = (string) => {
  //   if (typeof string !== 'string') return '';
    

  //   const indexOfHyphen = string.indexOf('-');
    

  //   if (indexOfHyphen === -1) return string.charAt(0).toUpperCase() + string.slice(1);
    

  //   const substringAfterHyphen = string.slice(indexOfHyphen + 1);
    
    
  //   return substringAfterHyphen.charAt(0).toUpperCase() + substringAfterHyphen.slice(1).replace(/-/g, ' ');
  // };
  const extractAndCapitalizeAfterHyphen = (string) => {
    if (typeof string !== 'string') return '';
  
   
    const indexOfHyphen = string.indexOf('-');
    
    
    if (indexOfHyphen === -1) return '';
  
    
    const substringAfterHyphen = string.slice(indexOfHyphen + 1).trim();
  

    if (substringAfterHyphen.length === 0) return '';
    
    return substringAfterHyphen
      .split(/[\s-]+/) 
      .filter(word => word.length > 0) 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
      .join(' ');
  };
  const titleheading  = subcategoryName
  return (
  
    <div>
       <Helmet>
        <title> {extractAndCapitalizeAfterHyphen(titleheading)}  Manufacturer in India</title>
        {/* <meta name="description" content="Best India Kart stands as India’s top fasteners manufacturer, providing durable, precision-engineered bolts, nuts, and screws to meet the needs of diverse industries."/> */}
        {/* <meta name="keywords" content="Jobs, Service,Automobiles,Store, Education, Travels, Event"/> */}
      </Helmet>
      {/* <h1 style={{ textAlign: "center" }}>{subcategoryName} Products</h1> */}
      {/* {console.log(subcategoryName,"dddddddddddddddd")} */}
      {sortedArray?.map((sortedArray) => (
        <ViewSubCategorybydescriptions
          key={sortedArray}
          sortArray={sortedArray}

          subcategoryName={subcategoryName}
          slug={slug}
        />
        // console.log("sorted array ------>",sortedArray)
      ))}
    </div>
  );
}

export default SubCategory;
