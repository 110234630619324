// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import AddCartButton from "../Buttons/AddCartButton";
// import WishlistButton from "../Buttons/WishlistButton";
// import ProductOveriew from "../ProductOveriew/ProductOveriew";
// import ProductImages from "../Products/ProductImages/ProductImages";
// import AdsPoster from "../../Assets/Images/tools_ads_poster.png";
// import Loader from "../Loader";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";

// function ToolsProductCards({ categoryId }) {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`)
//         .then((response) => {
//           setData(response.data.products.slice(0, 8));
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [categoryId]);

//   return (
//     <>
//       {data.length !== 0 ? (
//         <div className="products m-0">
//           {data.map((product) => {
//             return (
//               <div className="card">
//                 <center>
//                   <Card.Title className="px-2 text-dark card-title">
//                     <Link
//                       className="text-dark text-decoration-none "
//                       to={`/product/${product._id}`}
//                     >
//                       {product.partNumber}
//                     </Link>
//                   </Card.Title>
//                 </center>

//                 <Link
//                   className="text-dark text-decoration-none "
//                   to={`/product/${product._id}`}
//                 >
//                   <ProductImages productimages={product.productimages} />
//                 </Link>

//                 <center className="cart-footer">
//                   <div className="card-price">
//                     <h6 className="m-0 current-price">₹ {product.saleprice}</h6>
//                     <p className="m-0 old-price">₹ {product.oldprice}</p>
//                   </div>

//                   <AddCartButton
//                     type="product_button"
//                     className="card-add-cart-button"
//                     data={product}
//                   />
//                 </center>

//                 <div className="card-content text-danger">
//                   <WishlistButton type="icon" data={product} />
//                   <div className="add-to-card">
//                     <ProductOveriew product={product} />
//                   </div>
//                 </div>
//               </div>
//             );
//           })}

//           <Link to="/view-all-tools-products" className="add-poster">
//             <img src={AdsPoster} alt="AdsPoster" />
//           </Link>
//         </div>
//       ) : (
//         <Loader />
//       )}
//     </>
//   );
// }

// export default ToolsProductCards;

//======================================================================================================================================================
//======================================================================================================================================================

//Before optimization

//======================================================================================================================================================
//======================================================================================================================================================

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Card from "react-bootstrap/Card";
// import AddCartButton from "../Buttons/AddCartButton";
// import WishlistButton from "../Buttons/WishlistButton";
// import ProductOveriew from "../ProductOveriew/ProductOveriew";
// import ProductImages from "../Products/ProductImages/ProductImages";
// import AdsPoster from "../../Assets/Images/tools_ads_poster.png";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import Lottie from "lottie-react";
// import NoProductFound from "../../Preloader/No product Found Animation.json";
// import Preloader from "../../Preloader/Preloader-Animation.json";

// function ToolsProductCards({ categoryId }) {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         if (categoryId) {
//           const response = await axios.get(`${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`);
//           setData(response?.data?.products?.slice(0, 8));
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [categoryId]);

//   return (
//     <>
//       {loading ? (
//         <Lottie
//           style={{ width: "100vw", height: "50vh" }}
//           animationData={Preloader}
//         />
//       ) : (
//         <>
//           {data.length !== 0 ? (
//             <div className="products m-0">
//               {data.map((product) => (
//                 <Card className="card result-card" key={product?._id}>
//                   <center>
//                     <Card.Title className="px-2 text-dark card-title">
//                       <Link
//                         className="text-dark text-decoration-none"
//                         to={`/product/${product._id}`}
//                       >
//                         {product?.partNumber}
//                       </Link>
//                     </Card.Title>
//                   </center>

//                   <Link
//                     className="text-dark text-decoration-none"
//                     to={`/product/${product?._id}`}
//                   >
//                     <ProductImages productimages={product?.productimages} />
//                   </Link>

//                   <center className="cart-footer">
//                     <div className="card-price">
//                       <h6 className="m-0 current-price">
//                         ₹ {product?.saleprice}
//                       </h6>
//                       <p className="m-0 old-price">₹ {product?.oldprice}</p>
//                     </div>

//                     <AddCartButton
//                       type="product_button"
//                       className="card-add-cart-button"
//                       data={product}
//                     />
//                   </center>

//                   <Card.Body className="card-content text-danger">
//                     <WishlistButton type="icon" data={product} />
//                     <div className="add-to-card">
//                       <ProductOveriew product={product} />
//                     </div>
//                   </Card.Body>
//                 </Card>
//               ))}

//               <Link to="/view-all-tools-products" className="add-poster">
//                 <img src={AdsPoster} alt="AdsPoster" />
//               </Link>
//             </div>
//           ) : (
//             <Lottie
//               style={{ width: "100vw", height: "50vh" }}
//               animationData={NoProductFound}
//             />
//           )}
//         </>
//       )}
//     </>
//   );
// }

// export default ToolsProductCards;

//======================================================================================================================================================
//======================================================================================================================================================

//After optimization

//======================================================================================================================================================
//======================================================================================================================================================

import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import AddCartButton from "../Buttons/AddCartButton";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";
import ProductImages from "../Products/ProductImages/ProductImages";
import AdsPoster from "../../Assets/Images/tools_ads_poster.png";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import Lottie from "lottie-react";
import NoProductFound from "../../Preloader/No product Found Animation.json";
import Preloader from "../../Preloader/Preloader-Animation.json";
import ReactImageGallery from "react-image-gallery";

const ToolsProductCards = memo(({ categoryId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ads, setAdsFaster] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (categoryId) {
          const response = await axios.get(
            `${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`
          );
          setData(response?.data?.products?.slice(0, 8));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId]);
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data: response } = await axios.get(
          `${API_BASE_URL}/offerposter?category=${categoryId}`
        );
        setAdsFaster(response?.posters || []);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };
    fetchAds();
  }, []);

  const images = ads.flatMap((ad, index) =>
    ad.offerposters.map((poster, posterIndex) => ({
      original: poster.offerposter,
      key: `${index}-${posterIndex}`,
    }))
  );

  const gallerySettings = {
    autoPlay: true,
    slideInterval: 3000,
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: false,
    showThumbnails: false,
    showBullets: false,
  };
  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-').toLocaleLowerCase();
    }else return urlString;
}
  return (
    <>
      {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (
        <>
          {data.length !== 0 ? (
            <div className="products m-0">
              {data.map((product) => (
                <Card className="card result-card" key={product?._id}>
                  <center>
                    <Card.Title className="px-2 text-dark card-title">
                      <Link
                        className="text-dark text-decoration-none"
                        // to={`/product/${product._id}`}
                        // to={`/product/${product.partNumber}`}
                        // to={`/product/${product?.slug}`}
                        to={
                          product?.descriptions[0]?.value !== undefined
                            ? `/${replaceSpacesWithHyphens(product?.category?.name)}/${replaceSpacesWithHyphens(product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${product?.slug}`
                            : `/product/${product?.slug}`
                        }
                      >
                        {product?.partNumber}
                      </Link>
                    </Card.Title>
                  </center>

                  <Link
                    className="text-dark text-decoration-none"
                    // to={`/product/${product?._id}`}
                    // to={`/product/${product?.slug}`}
                    to={
                      product?.descriptions[0]?.value !== undefined
                        ? `/${replaceSpacesWithHyphens(product?.category?.name)}/${replaceSpacesWithHyphens(product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}/${product?.slug}`
                        : `/product/${product?.slug}`
                    }
                  >
                    <ProductImages productimages={product?.productimages} />
                  </Link>

                  <center className="cart-footer">
                    <div className="card-price">
                      <h6 className="m-0 current-price">
                        ₹ {product?.saleprice}
                      </h6>
                      <p className="m-0 old-price">₹ {product?.oldprice}</p>
                    </div>

                    <AddCartButton
                      type="product_button"
                      className="card-add-cart-button"
                      data={product}
                    />
                  </center>

                  <Card.Body className="card-content text-danger">
                    <WishlistButton type="icon" data={product} />
                    <div className="add-to-card">
                      <ProductOveriew product={product} />
                    </div>
                  </Card.Body>
                </Card>
              ))}

              {/* <Link to="/view-all-tools-products" className="add-poster">
                <MemoizedImage src={AdsPoster} alt="AdsPoster" />
              </Link> */}
              <Link to="view-all-tools-products" className="add-poster">
                {images.length > 0 && (
                  <ReactImageGallery
                    items={images}
                    className="banner-image"
                    {...gallerySettings}
                  />
                )}
              </Link>
            </div>
          ) : (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={NoProductFound}
            />
          )}
        </>
      )}
    </>
  );
});

// const MemoizedImage = memo(({ src, alt }) => <img src={src} alt={alt} />);

export default ToolsProductCards;
