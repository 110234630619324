import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

function CartTotal({ products }) {
  const isLoggedIn = localStorage.getItem("userId");
  const navigate = useNavigate();
// console.log("products",products)
  return (
    <div className="cart-collaterals">
      <div className="cart_totals">
        <div className="cart-row">
          <h6>Cart totals </h6>
        </div>

        <div className="shop_table">
          {products &&
            products?.filter(
                (product) =>
                  product?.type !== "GST (18%)" && product?.type !== "shipping"
              )
              .map((product, index) => (
                <div key={index} className="shop-subtotal cart-row py-2">
                  <p className="m-0 shop-title">{product?.type}</p>
                  <h5>₹ {product?.value}</h5>
                </div>
              ))}
        </div>

        <div className="checkout-button">
          {isLoggedIn ? (
            <Link to="/checkout">
              <p className="m-0">Proceed to checkout</p>
            </Link>
          ) : (
            <p
              className="m-0"
              onClick={() => {
                Swal.fire({
                  title: "Please Login, Authorized People Only Buy",
                  showClass: {
                    popup: `
                                animate__animated
                                animate__fadeInUp
                                animate__faster
                              `,
                  },
                  hideClass: {
                    popup: `
                                animate__animated
                                animate__fadeOutDown
                                animate__faster
                              `,
                  },
                }).then(() => {
                  navigate("/login");
                });
              }}
            >
              Proceed to checkout
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartTotal;