import React, { useState } from "react";
import loginLogo from "../../Assets/Images/brand-logo.svg";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import { GoKey } from "react-icons/go";
import { FiLock } from "react-icons/fi";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import "react-toastify/dist/ReactToastify.css";
import { CiMail } from "react-icons/ci";
import { IoIosCall, IoIosContact } from "react-icons/io";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

function Registration() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   axios
  //     .post(`${API_BASE_URL}/registration`, {
  //       name: name,
  //       mobileno: mobile,
  //       email: email,
  //       password: password,
  //       confirmPassword: confirmPassword,
  //     })
  //     .then((result) => {
  //       // console.log(result);
  //       Swal.fire({
  //         title: "Registration Success!",
  //         text: "The login link has been sent to your mail",
  //         icon: "success",
  //         timer: 300000, 
  //         timerProgressBar: true,
  //         willClose: () => {
  //           clearTimeout(timer);
  //         },
  //       });
   
  //     })
  //     .catch((error) => {
  //       console.error("Error in API call:", error);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: error.response.data.message,
  //       });
  //     });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post(`${API_BASE_URL}/registration`, {
        name: name,
        mobileno: mobile,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((result) => {
        // Start the timer for the Swal
        const timer = setTimeout(() => {
          // You can navigate or perform any action here if needed
        }, 300000); // 300000 milliseconds = 5 minutes
  
        Swal.fire({
          title: "Registration Success!",
          text: "The login link has been sent to your mail",
          icon: "success",
          timer: 300000, 
          timerProgressBar: true,
          willClose: () => {
            clearTimeout(timer);
          },
        });
      })
      .catch((error) => {
        console.error("Error in API call:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="login-page">
      <div className="login-page-inner">
        <div className="text-center">
          <img className="login-logo" src={loginLogo} alt="loginLogo" />
        </div>
        <form className="right py-2" name="RegForm" onSubmit={handleSubmit}>
          <center className="login-title-registration">
            <h6 className="fw-4">User Registration</h6>
          </center>

          <div className="form-control">
            <IoIosContact className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </div>

          <div className="form-control">
            <IoIosCall className="form-control-icon" />
            <input
              className="form-control-input"
              type="number"
              name="number"
              id="number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Mobile Number"
              required
            />
          </div>

          <div className="form-control">
            <CiMail className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Your Email"
            />
          </div>

          <div className="form-control">
            <GoKey className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="toggle-password"
            >
              {showPassword ? (
                <IoEyeSharp className="eye-icon" />
              ) : (
                <IoEyeOffSharp className="eye-icon" />
              )}
            </button>
          </div>

          <div className="form-control">
            <FiLock className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="toggle-password"
            >
              {showConfirmPassword ? (
                <IoEyeOffSharp className="eye-icon" />
              ) : (
                <IoEyeSharp className="eye-icon" />
              )}
            </button>
          </div>

          <div className="d-flex justify-content-center">
            <button type="submit" value="send" className="submit-button">
              Continue
            </button>
          </div>
          <div className="text-center">
            <Link
              to="/login"
              className="forgot-password d-flex justify-content-center"
            >
              {" "}
              Alredy have a account to login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Registration;
