// import React, { useContext, useEffect, useState } from "react";
// import userContext from "../UseContext/UseContext";
// import { Link } from "react-router-dom";
// import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
// import { IoMdContact } from "react-icons/io";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";

// function AuthAndCartStatus() {
//   const userId = localStorage.getItem("userId");
//   const store = useContext(userContext);
//   const impureArray = localStorage.getItem("CartProducts");
//   const isLoggedIn = localStorage.getItem("userId");
//   const [cartLength, setCartLength] = useState("");

//   useEffect(() => {
//     try {
//       if (isLoggedIn) {
//         const userId=localStorage.getItem("userId")
//         const headers1 = {
//           Authorization: `Bearer ${userId}`,
//           "Content-Type": "application/json",
//         };
//         axios
//           .get(`${API_BASE_URL}/user/order/getcart`, {
//             headers:headers1,
//           })
//           .then((res) => {
//             setCartLength(res.data.cartItemLength);
//             // localStorage.setItem("addtocartLength", res.data.cartItemLength);
//           })
//           .catch((error) => {
//             console.log("error", error);
//           });
//       }
//     } catch (error) {
//       console.log("error messafe from  auth and cart status", error.data);
//     }
//   });

//   let arrayOfObjects = [];

//   if (impureArray) {
//     try {
//       arrayOfObjects = JSON.parse(impureArray);
//     } catch (error) {
//       console.error("Invalid JSON string", error);
//     }
//   } else {
//     console.log("No cart products found in localStorage.");
//   }

//   return (
//     <ul className="auth-an-cart-status-group auth-an-cart-status-group-rsponsive">
//       {userId ? (
//         <li className="auth-status cart-status">
//           <Link to="/profile" className="login">
//             <IoMdContact className="navbar-icon" />
//           </Link>
//         </li>
//       ) : (
//         <li className="auth-status">
//           <Link to="/loginpage" className="login">
//             Log In
//           </Link>
//           <span>/</span>
//           <Link to="/registration" className="login">
//             Register
//           </Link>
//         </li>
//       )}

//       <li className="cart-status">
//         <Link to="/wishlist">
//           <AiOutlineHeart className="navbar-icon" />
//           <div className="badge">{store?.wishlist?.length}</div>
//         </Link>
//       </li>

//       <li className="cart-status">
//         <Link to="/carts">
//           <AiOutlineShoppingCart className="navbar-icon" />

//           <div className="badge">
//             {/* {store?.cartItems ? (
//               // ? store?.cartItems?.cartItemLength
//               <>
//                 {localStorage?.getItem("addtocartLength")}{" "}
//                 {console.log("data is coming con")}
//               </>
//             ) : (
//               // : localStorage?.getItem("addtocartLength") || 0}
//               arrayOfObjects?.length || 0
//             )} */}
//             {isLoggedIn ? cartLength : arrayOfObjects?.length || 0}
//           </div>
//         </Link>
//       </li>
//     </ul>
//   );
// }

// export default AuthAndCartStatus;

import React, { useContext, useEffect, useState } from "react";
import userContext from "../UseContext/UseContext";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { IoMdContact } from "react-icons/io";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dropdown from "react-bootstrap/Dropdown";

function AuthAndCartStatus() {
  const userId = localStorage.getItem("userId");
  const store = useContext(userContext);
  const [cartLength, setCartLength] = useState(0);
  const [profileStatus, setProfileStatus] = useState("false");
  const [logOut, setLogOut] = useState(false);

  // console.log("store from the cart", store.cart);

  useEffect(() => {
    if (userId) {
      const headers = {
        Authorization: `Bearer ${userId}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${API_BASE_URL}/user/order/getcart`, { headers: headers })
        .then((res) => {
          setCartLength(res.data.cartItemLength || 0);
        })
        .catch((error) => {
          console.error("Error fetching cart length:", error);
        });
    }
  }, [userId]);

  let arrayOfObjects = [];
  const uniqueProducts = new Set();

  const impureArray = localStorage.getItem("CartProducts");

  if (impureArray) {
    try {
      arrayOfObjects = JSON.parse(impureArray);

      // Filter the array to include only unique productId values

      // arrayOfObjects.productId
    } catch (error) {
      console.error("Invalid JSON string in localStorage:", error);
    }
  }

  const filteredArray = arrayOfObjects.filter((item) => {
    if (!uniqueProducts.has(item.productId)) {
      uniqueProducts.add(item.productId);
      return true;
    }
    return false;
  });

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("refreshToken");
    setLogOut(true);
  };

  // Output the length of the filtered array
  // console.log("Unique products count:", filteredArray.length);

  // Output the filtered array
  // console.log("Filtered array:", filteredArray);

  const profileDashboardLogout = () => {
    setProfileStatus(true);
  };

  return (
    <ul className="auth-an-cart-status-group auth-an-cart-status-group-rsponsive p-0">
      {userId ? (
        <li className="auth-status cart-status">
          <Link className="login">
            <IoMdContact className="navbar-icon" />
          </Link>

          {/* <Stack direction="row" spacing={2} style={{display:`${profileStatus} ? inline : none`  }}>
            <Button variant="contained">Logout</Button>

            <Button variant="contained">
              <Link
                to="/profile"
                style={{ textDecoration: "none", color: "white" }}
              >
                Dashboard
              </Link>
            </Button>
          </Stack> */}
          <Dropdown className="user" style={{ background: "transparent" }}>
            <Dropdown.Toggle

            // variant="secondary"
            >
              <IoMdContact className="" />

              {/* <CgProfile className="mx-2 text-dark fs-4" /> */}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{ padding: "0px", borderRadius: "30%", marginTop: "15px" }}
            >
              <Dropdown.Item
                href="/profile"
                style={{
                  background: "#0443C0",
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                Profile Page
              </Dropdown.Item>
              <Dropdown.Divider style={{ margin: "0px" }} />
              <div
                className="w-100 d-flex align-items-center justify-content-center"
                style={{ background: "#F04B25" }}
              >
                <button
                  className="py-1 px-2 rounded  logout"
                  onClick={handleLogout}
                  style={{
                    border: "none",
                    background: "transparent",
                    color: "white",
                  }}
                >
                  Log Out
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      ) : (
        <li className="auth-status">
          <Link to="/login" className="login">
            Log In
          </Link>
          <span>/</span>
          <Link to="/registration" className="login">
            Register
          </Link>
        </li>
      )}

      {/* <li className="cart-status">
        <Link to="/wishlist">
        <AiOutlineHeart
  className="navbar-icon"
  style={{ color: userId && store?.wishlist.length > 0 ? "#F04B25" : "" }}
/>
          <div className="badge">{store?.wishlist?.length || 0}</div>
        </Link>
      </li> */}
<li className="cart-status">
  <Link to="/wishlist">
    <AiOutlineHeart
      className="navbar-icon"
      style={{ color: store?.wishlist?.length > 0 ? "#F04B25" : "" }} // Default color is black
    />
    <div className="badge">{store?.wishlist?.length || 0}</div>
  </Link>
</li>


      {/* <li className="cart-status">
        <Link to="/carts">
        
          <AiOutlineShoppingCart
            className={`navbar-icon ${
              userId
                ? store?.addCartLength > 0
                  ? "icon-blue"
                  : ""
                : filteredArray.length > 0
                ? "icon-blue"
                : ""
            }`}
          />
          <div className="badge">
            {userId ? store?.addCartLength || 0 : filteredArray.length || 0}
          </div>
        </Link>
      </li> */}
      <li className="cart-status">
        <Link to="/carts">
          <AiOutlineShoppingCart
            className={`navbar-icon  ${
              userId
                ? store?.addCartLength > 0
                : filteredArray.length > 0
                ? ""
                : ""
            }`}
            style={{ color : `${ userId
              ? store?.addCartLength > 0
              : filteredArray.length > 0
              ? "#F04B25"
              : ""}` }}
          />
          <div className="badge" 
          //  style={{ color : `${ userId
          //   ? store?.addCartLength > 0
          //   : filteredArray.length > 0
          //   ? "#F04B25"
          //   : ""}` }}
    
          
          >
            {userId ? store?.addCartLength || 0 : filteredArray.length || 0}
          </div>
        </Link>
      </li>
    </ul>
  );
}

export default AuthAndCartStatus;
