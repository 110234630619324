import React from "react";
import FastenersProducts from "./FastenersProducts/FastenersProducts";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import FastenersProductIdProvider from "../hoc/FastenersProductIdProvider";
import { Button } from "react-bootstrap";

function Products({ categoryId, categoryName }) {
  return (
    <div className="products-wrapper px-2r py-4">
      <div className="products-heading-container ">
        <h4 className="product-heading  mt-2" > {categoryName} </h4>
      

        <Button className="view-all-products text-end" style={{background:"#0443C0"}}>

        <Link to="/view-all-products" >
          View All <IoIosArrowForward />
        </Link>
        </Button>
      
      </div>

      <FastenersProducts categoryId={categoryId} />
    </div>
  );
}

export default FastenersProductIdProvider(Products);