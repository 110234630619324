import React, { useState } from "react";
import loginLogo from "../../Assets/Images/brand-logo.svg";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import { GoKey } from "react-icons/go";
import { FiLock } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const navigate = useNavigate();
  const { resetToken } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      newPassword,
      confirmPassword,
      resetToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    axios
      .post(`${API_BASE_URL}/resetpassword/` + resetToken, {
        newPassword,
        confirmPassword,
        resetToken,
      })
      .then((result) => {
        // console.log(result);
        Swal.fire({
          icon: "success",
          title: "Reset Password Success",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/login");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="login-page">
      <div className="login-page-inner">
        <div className="text-center">
          <img className="login-logo" src={loginLogo} alt="loginLogo" />
        </div>

        <form className="right py-2" name="RegForm" onSubmit={handleSubmit}>
          <h6 className="m-0 text-center">Reset Password</h6>
          <div className="form-control">
            <GoKey className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type="password"
              name="newPassword"
              id="password"
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className="form-control">
            <FiLock className="form-control-icon" />
            <input
              className="form-control-input"
              required
              type="Password"
              name="confirmPassword"
              id="Confirm Password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-center">
            <button type="submit" value="send" className="submit-button">
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
