// import React, { useEffect, useState } from "react";
// import "../../Common/Common.scss";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils";
// import { Link } from "react-router-dom";
// import ProductImages from "./ProductImages/ProductImages";
// import AddCartButton from "../Buttons/AddCartButton";
// import WishlistButton from "../Buttons/WishlistButton";
// import ProductOveriew from "../ProductOveriew/ProductOveriew";
// import Card from "react-bootstrap/Card";
// import { IoCaretBack } from "react-icons/io5";
// import Loader from "../Loader";
// import FastenersProductIdProvider from "../hoc/FastenersProductIdProvider";

// function ViewAllProducts({ categoryId, categoryName }) {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`)
//         .then((response) => {
//           setData(response.data.products);
//           console.log("getproductbycategory", response.data);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [categoryId]);

//   return (
//     <>
//       <div className="products-wrapper px-2r py-4">
//         <center className="products-heading-container">
//           <h4 className="product-heading"> {categoryName} </h4>
//           <Link to="/" className="view-all-products">
//             <IoCaretBack className="fs-5" />
//             Go Back
//           </Link>
//         </center>

//         {data.length !== 0 ? (
//           <div className="products m-0">
//             {data.map((product) => {
//               return (
//                 <div className="card">
//                   <center>
//                     <Card.Title className="px-2 text-dark card-title">
//                       <Link
//                         className="text-dark text-decoration-none "
//                         to={`/product/${product._id}`}
//                       >
//                         {product.partNumber}
//                       </Link>
//                     </Card.Title>
//                   </center>

//                   <Link to={`/product/${product._id}`}>
//                     <ProductImages productimages={product.productimages} />
//                   </Link>

//                   <center className="cart-footer">
//                     <div className="card-price">
//                       <h6 className="m-0 current-price">
//                         ₹ {product.saleprice}
//                       </h6>
//                       <p className="m-0 old-price">₹ {product.oldprice}</p>
//                     </div>

//                     <AddCartButton
//                       type="product_button"
//                       className="card-add-cart-button"
//                       data={product}
//                     />
//                   </center>

//                   <div className="card-content text-danger">
//                     <WishlistButton type="icon" data={product} />
//                     <div className="add-to-card">
//                       <ProductOveriew product={product} />
//                     </div>
//                     {/* <AddCartButton
//                     type="icon"
//                     className="bg-primary"
//                     data={product}
//                   /> */}
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         ) : (
//           <Loader />
//         )}
//       </div>
//     </>
//   );
// }

// export default FastenersProductIdProvider(ViewAllProducts);

import React, { useEffect, useState } from "react";
import "../../Common/Common.scss";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { Link } from "react-router-dom";
import ProductImages from "./ProductImages/ProductImages";
import AddCartButton from "../Buttons/AddCartButton";
import WishlistButton from "../Buttons/WishlistButton";
import ProductOveriew from "../ProductOveriew/ProductOveriew";
import Card from "react-bootstrap/Card";
import { IoCaretBack } from "react-icons/io5";
import Loader from "../Loader";
import FastenersProductIdProvider from "../hoc/FastenersProductIdProvider";
import Lottie from "lottie-react";
import Preloader from "../../Preloader/Preloader-Animation.json";

function ViewAllProducts({ categoryId, categoryName }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subCategoryName, setSubCategoryName] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (categoryId) {
          const response = await axios.get(
            `${API_BASE_URL}/user/product/getproductbycategory/${categoryId}`
          );
          setData(response?.data?.products);
          setSubCategoryName(
            response?.data?.products[0]?.subcategoryname?.name
          );
          // console.log("getproductbycategory", response?.data?.products[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId]);

  const replaceSpacesWithHyphens = (urlString) => {

    if (typeof urlString === 'string') {
      // Replace all spaces with hyphens
      return urlString.replace(/ /g, '-').toLocaleLowerCase();
    }else return urlString;
}
  return (
    <>
      <div className="products-wrapper px-2r py-4">
        <center className="products-heading-container">
          <h1 className="product-heading"> {categoryName} </h1>
          <Link to="/" className="view-all-products">
            <IoCaretBack className="fs-5" />
            Go Back
          </Link>
        </center>

        {loading ? (
          <Lottie
            style={{ width: "100vw", height: "50vh" }}
            animationData={Preloader}
          />
        ) : (
          <div className="products m-0">
            {data?.map((product) => (
              <div key={product?._id} className="card">
                <center>
                  <Card.Title className="px-2 text-dark card-title">
                    <Link
                      className="text-dark text-decoration-none "
                      // to={`/product/${product?._id}`}
                      // to={`/product/${product?.partNumber}`}
                      to={
                        product?.descriptions[0]?.value !== undefined
                          ? `/${replaceSpacesWithHyphens(categoryName)}/${replaceSpacesWithHyphens( product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}`
                          : `/product/${product?.slug}`
                      }
                    >
                      {product?.partNumber}
                    </Link>
                  </Card.Title>
                </center>

                {/* <Link to={`/product/${product?.partNumber}`}> */}
                <Link
                  to={
                    product?.descriptions[0]?.value !== undefined
                      ? `/${replaceSpacesWithHyphens(categoryName)}/${replaceSpacesWithHyphens( product?.subcategoryname?.name)}/${replaceSpacesWithHyphens(product?.descriptions[0]?.value)}`
                      : `/product/${product?.slug}`
                  }
                >
                  <ProductImages productimages={product.productimages} />
                </Link>

                <center className="cart-footer">
                  <div className="card-price">
                    <h6 className="m-0 current-price">
                      ₹ {product?.saleprice}
                    </h6>
                    <p className="m-0 old-price">₹ {product?.oldprice}</p>
                  </div>

                  <AddCartButton
                    type="product_button"
                    className="card-add-cart-button"
                    data={product}
                  />
                </center>

                <div className="card-content text-danger">
                  <WishlistButton type="icon" data={product} />
                  <div className="add-to-card">
                    <ProductOveriew product={product} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default FastenersProductIdProvider(ViewAllProducts);
