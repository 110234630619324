// import React from 'react';
// import { FaHome } from 'react-icons/fa';
// import { Link, useLocation } from 'react-router-dom';

// const Breadcrumbs = () => {
//   const location = useLocation();
//   const pathnames = location.pathname.split('/').filter((item) => item);
// // console.log(pathnames,"Ssssssssssssssssss")
//   // Don't render breadcrumbs on the home page
//   if (location.pathname === '/') {
//     return null;
//   }
//   function capitalizeFirstLetter(string) {
//     if (!string) return string;
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   }

//   return (
//     <nav aria-label="breadcrumb " >
//       <ol className="breadcrumb justify-content-center m-2" >
//         <li className="breadcrumb-item" >
//           <Link to="/" className='' style={{ textDecoration: 'none',color:"#a19e99"}} onMouseEnter={(e) => e.currentTarget.style.color = "var(--bc-color)"} onMouseLeave={(e) => e.currentTarget.style.color = "#a19e99"}>Home</Link>
//         </li>
//         {pathnames.map((name, index) => {
//           // Replace '%20' with '-' for the breadcrumb name
//           const formattedName = name.replace(/%20/g, '-');
//           const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
//           const isLast = index === pathnames.length - 1;
//           return isLast ? (
//             <li key={name} className="breadcrumb-item active text-black " aria-current="page"  >
//             {/* <li key={name} className="breadcrumb-item active fs-5" aria-current="page" style={{ textDecoration: 'none' ,color:"black"}}> */}
//               {formattedName}
//             </li>
//           ) : (
//             <li key={name} className="breadcrumb-item " >
//               {/* <Link to={routeTo}  onMouseEnter={(e) => e.currentTarget.style.color = "var(--bc-color)"} onMouseLeave={(e) => e.currentTarget.style.color = "black"}>{formattedName}</Link> */}
//               <Link to={routeTo} style={{ textDecoration: 'none',color:"#a19e99"}} onMouseEnter={(e) => e.currentTarget.style.color = "var(--bc-color)"} onMouseLeave={(e) => e.currentTarget.style.color = "#a19e99"}>{formattedName}</Link>
//             </li>
//           );
//         })}
//       </ol>
//     </nav>
//   );
// };

// export default Breadcrumbs;
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((item) => item);

  // Don't render breadcrumbs on the home page
  if (location.pathname === "/") {
    return null;
  }

  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center m-2">
        <li className="breadcrumb-item">
          <Link
            to="/"
            className=""
            style={{ textDecoration: "none", color: "#a19e99" }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.color = "var(--bc-color)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.color = "#a19e99")}
          >
            Home
          </Link>
        </li>
        {pathnames.map((name, index) => {
          // Replace '%20' with '-' and capitalize the first letter for breadcrumb name
          const formattedName = capitalizeFirstLetter(
            name.replace(/%20/g, "-")
          );
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <li
              key={name}
              className="breadcrumb-item active text-black"
              aria-current="page"
            >
              {formattedName}
            </li>
          ) : (
            <li key={name} className="breadcrumb-item">
              <Link
                to={routeTo}
                style={{ textDecoration: "none", color: "#a19e99" }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.color = "var(--bc-color)")
                }
                onMouseLeave={(e) => (e.currentTarget.style.color = "#a19e99")}
              >
                {formattedName}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
