// import React, { useEffect, useState, useContext } from "react";
// import "../ProductDeital/ProductDeital.scss";
// import { useParams } from "react-router-dom";
// import KeepUp from "../../components/KeepUp/KeepUp";
// import ContactSection from "../../components/ContactSection/ContactSection";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import AddCartButton from "../../components/Buttons/AddCartButton";
// import WishlistButton from "../../components/Buttons/WishlistButton";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import Spinner from "react-bootstrap/Spinner";
// import Reviews from "./Reviews";
// import RelatedProduct from "./RelatedProduct";
// import Specification from "./Specification";
// import userContext from "../../components/UseContext/UseContext";
// import Loader from "../../components/Loader";

// function LandingPageInnerPage() {
//   const { _id } = useParams();
//   const [detail, setDetail] = useState(null);
//   const reloadReview = useContext(userContext);

//   const reloadReviewState = reloadReview?.productReviews;

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/user/products/singleproduct/${_id}`)
//       .then((response) => {
//         setDetail(response?.data?.product);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, [reloadReviewState, _id]);

//   if (detail === null) {
//     return (
//       <div className="d-flex align-items-center justify-content-center my-2">
//         <Loader />
//       </div>
//     );
//   } else {
//     return (
//       <>
//         <div className="row-container">
//           <div className="detail-section row m-0">
//             <div className="col-md-6 col-lg-5 detail-section-content">
//               <img
//                 className="detail-products-image"
//                 src={detail?.productimages[0]?.productimage}
//                 alt={`Product ${detail?.productimages[0]?.id}`}
//               />
//             </div>

//             <div className="col-md-6 col-lg-7 detail-section-content">
//               <div className="detail-content">
//                 <h5 className="detail-content-title">
//                   {detail?.category?.name} / {detail?.subcategoryname?.name}
//                 </h5>
//                 <p className="detail-content-para">{detail?.description}</p>
//                 <div className="detail-content-price text-danger">
//                   Rs.{detail?.saleprice}/-
//                 </div>

//                 <Tabs
//                   defaultActiveKey="profile"
//                   id="uncontrolled-tab-example"
//                   className="mb-3"
//                 >
//                   <Tab
//                     eventKey="profile"
//                     title="Specification"
//                     className="text-danger"
//                   >
//                     <Specification detail={detail} />
//                   </Tab>
//                   <Tab
//                     eventKey="home"
//                     title={`Reviews ( ${detail?.reviews?.length || 0} )`}
//                     className="text-danger"
//                   >
//                     <Reviews productId={_id} comments={detail?.reviews} />
//                   </Tab>
//                 </Tabs>

//                 <div className="d-flex flex-wrap add-to-buttons">
//                   <AddCartButton type="button" data={detail} />
//                   <WishlistButton
//                     type="button"
//                     data={detail}
//                     className="mx-2"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <RelatedProduct detail={detail} id={_id} />

//         <KeepUp />
//         <ContactSection />
//       </>
//     );
//   }
// }

// export default LandingPageInnerPage;


//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//                                              without lazy methods
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------


// import React, { useEffect, useState, useContext } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import Lottie from "lottie-react";
// import { API_BASE_URL } from "../../utils/index";
// import userContext from "../../components/UseContext/UseContext";
// import KeepUp from "../../components/KeepUp/KeepUp";
// import ContactSection from "../../components/ContactSection/ContactSection";
// import AddCartButton from "../../components/Buttons/AddCartButton";
// import WishlistButton from "../../components/Buttons/WishlistButton";
// import Reviews from "./Reviews";
// import RelatedProduct from "./RelatedProduct";
// import Specification from "./Specification";
// import Preloader from "../../Preloader/Preloader-Animation.json";
// import "../ProductDeital/ProductDeital.scss";

// function LandingPageInnerPage() {
//   const { _id } = useParams();
//   const [detail, setDetail] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const reloadReview = useContext(userContext);
//   const reloadReviewState = reloadReview?.productReviews;

//   useEffect(() => {
//     const fetchProductDetail = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(`${API_BASE_URL}/user/products/singleproduct/${_id}`);
//         setDetail(response?.data?.product);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProductDetail();
//   }, [reloadReviewState, _id]);

//   if (loading) {
//     return (
//       <div className="d-flex align-items-center justify-content-center my-2">
//         <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
//       </div>
//     );
//   }

//   if (!detail) {
//     return (
//       <div className="d-flex align-items-center justify-content-center my-2">
//         <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
//       </div>
//     );
//   }

//   return (
//     <>
//       <div className="row-container">
//         <div className="detail-section row m-0">
//           <div className="col-md-6 col-lg-5 detail-section-content">
//             <img
//               className="detail-products-image"
//               src={detail?.productimages[0]?.productimage}
//               alt={`Product ${detail?.productimages[0]?.id}`}
//             />
//           </div>
//           <div className="col-md-6 col-lg-7 detail-section-content">
//             <div className="detail-content">
//               <h5 className="detail-content-title">
//                 {detail?.category?.name} / {detail?.subcategoryname?.name}
//               </h5>
//               <p className="detail-content-para">{detail?.description}</p>
//               <div className="detail-content-price text-danger">
//                 Rs.{detail?.saleprice}/-
//               </div>
//               <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
//                 <Tab eventKey="profile" title="Specification" className="text-danger">
//                   <Specification detail={detail} />
//                 </Tab>
//                 <Tab eventKey="home" title={`Reviews (${detail?.reviews?.length || 0})`} className="text-danger">
//                   <Reviews productId={_id} comments={detail?.reviews} />
//                 </Tab>
//               </Tabs>
//               <div className="d-flex flex-wrap add-to-buttons">
//                 <AddCartButton type="button" data={detail} />
//                 <WishlistButton type="button" data={detail} className="mx-2" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <RelatedProduct detail={detail} id={_id} />
//       <KeepUp />
//       <ContactSection />
//     </>
//   );
// }

// export default LandingPageInnerPage;




//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//                                              with lazy methods
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------



// import React, { useEffect, useState, useContext, lazy, Suspense } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import Lottie from "lottie-react";
// import { API_BASE_URL } from "../../utils/index";
// import userContext from "../../components/UseContext/UseContext";
// import Preloader from "../../Preloader/Preloader-Animation.json";
// import "../ProductDeital/ProductDeital.scss";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// import ImageGallery from 'react-image-gallery';
// import 'react-image-gallery/styles/css/image-gallery.css'
// const KeepUp = lazy(() => import("../../components/KeepUp/KeepUp"));
// const ContactSection = lazy(() => import("../../components/ContactSection/ContactSection"));
// const AddCartButton = lazy(() => import("../../components/Buttons/AddCartButton"));
// const WishlistButton = lazy(() => import("../../components/Buttons/WishlistButton"));
// const Reviews = lazy(() => import("./Reviews"));
// const RelatedProduct = lazy(() => import("./RelatedProduct"));
// const Specification = lazy(() => import("./Specification"));

// function LandingPageInnerPage() {
//   const { _id } = useParams();
//   const [detail, setDetail] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const reloadReview = useContext(userContext);
//   const reloadReviewState = reloadReview?.productReviews;
//   const [productImage,setProductImage]=useState("");
//   useEffect(() => {
//     const fetchProductDetail = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(`${API_BASE_URL}/user/products/singleproduct/${_id}`);
//         setDetail(response?.data?.product[0]);
//         setProductImage(response?.data?.product[0]?.productimages[0])
//         console.log("product image ==========>",response?.data?.product[0]?.productimages[0]?.productimage)
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProductDetail();
//   }, [reloadReviewState, _id]);

//   if (loading) {
//     return (
//       <div className="d-flex align-items-center justify-content-center my-2">
//         <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
//       </div>
//     );
//   }

//   if (!detail) {
//     return (
//       <div className="d-flex align-items-center justify-content-center my-2">
//         <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
//       </div>
//     );
//   }
//   const galleryItems = detail?.map(image => ({
//     original: image.productimage,
//     thumbnail: image.productimage // Optionally add a thumbnail if available
//   }));
//   return (
//     <>
//       <div className="row-container">
//         <div className="detail-section row m-0">
//           <div className="col-md-6 col-lg-5 detail-section-content">
//             {/* <img
//               className="detail-products-image"
//               // src={detail?.productimages[0]?.productimage}
//               src={`${productImage?.productimage}`}
//               // alt={`Product ${detail?.productimages[0]?.id}`}
//               alt={`Product ${productImage?.id}`}
//             /> */}
//              <div className="product-gallery">
//       <ImageGallery items={galleryItems} />
//     </div>
//           </div>
//           <div className="col-md-6 col-lg-7 detail-section-content">
//             <div className="detail-content">
//               <h5 className="detail-content-title">
//                 {detail?.category?.name} / {detail?.subcategoryname?.name}
//               </h5>
//               <p className="detail-content-para">{detail?.description}</p>
//               <div className="detail-content-price text-danger">
//                 Rs.{detail?.saleprice}/-
//                 {console.log("details are come or not ",detail)}
//               </div>
//               <Suspense fallback={<div>Loading tabs...</div>}>
//                 <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
//                   <Tab eventKey="profile" title="Specification" className="text-danger">
//                     <Specification detail={detail} />
//                   </Tab>
//                   <Tab eventKey="home" title={`Reviews (${detail?.reviews?.length || 0})`} className="text-danger">
//                     <Reviews productId={_id} comments={detail?.reviews} />
//                   </Tab>
//                 </Tabs>
//               </Suspense>
//               <div className="d-flex flex-wrap add-to-buttons">
//                 <Suspense fallback={<div>Loading button...</div>}>
//                   <AddCartButton type="button" data={detail} />
//                 </Suspense>
//                 <Suspense fallback={<div>Loading button...</div>}>
//                   <WishlistButton type="button" data={detail} className="mx-2" />
//                 </Suspense>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Suspense fallback={<div>Loading related products...</div>}>
//         <RelatedProduct detail={detail} id={_id} />
//       </Suspense>
//       <Suspense fallback={<div>Loading sections...</div>}>
//         <KeepUp />
//         <ContactSection />
//       </Suspense>
//     </>
//   );
// }

// export default LandingPageInnerPage;
import React, { useEffect, useState, useContext, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Lottie from "lottie-react";
import { API_BASE_URL } from "../../utils/index";
import userContext from "../../components/UseContext/UseContext";
import Preloader from "../../Preloader/Preloader-Animation.json";
import "../ProductDeital/ProductDeital.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';import { Helmet } from "react-helmet";

const KeepUp = lazy(() => import("../../components/KeepUp/KeepUp"));
const ContactSection = lazy(() => import("../../components/ContactSection/ContactSection"));
const AddCartButton = lazy(() => import("../../components/Buttons/AddCartButton"));
const WishlistButton = lazy(() => import("../../components/Buttons/WishlistButton"));
const Reviews = lazy(() => import("./Reviews"));
const RelatedProduct = lazy(() => import("./RelatedProduct"));
const Specification = lazy(() => import("./Specification"));

function LandingPageInnerPage() {
  const { _id } = useParams();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const reloadReview = useContext(userContext);
  const reloadReviewState = reloadReview?.productReviews;
  const [galleryItems, setGalleryItems] = useState([]);




  // const replaceHyphensWithSpaces = (urlString) => {
  //   if (typeof urlString === 'string') {
  //     return urlString.replace(/-/g, ' ');
  //   }
  // };
  // const _id=replaceHyphensWithSpaces(params)

  // console.log(subcategory,"subcatrogty.....")
  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/user/products/singleproduct/${_id }`);
        const product = response?.data?.product?.[0] || {};
        
        setDetail(product);

        // Prepare gallery items from product images
        const images = product?.productimages || [];
        const formattedImages = images.map(image => ({
          original: image.productimage,
          thumbnail: image.productimage // Optionally add a thumbnail if available
        }));
        setGalleryItems(formattedImages);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [reloadReviewState, _id]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center my-2">
        <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
      </div>
    );
  }

  if (!detail) {
    return (
      <div className="d-flex align-items-center justify-content-center my-2">
        <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
      </div>
    );
  }

  return (
    <>
      <div className="row-container">
        <div className="detail-section row m-0">
          <div className="col-md-6 col-lg-5 detail-section-content">
            <div className="product-gallery mt-5">
              <ImageGallery items={galleryItems} width={"200px"} />
            </div>
          </div>
          <Helmet>
        <title>{detail?.metatitle}</title>
        <meta name="description" content={detail?.writenotes}/>

      </Helmet>
          <div className="col-md-6 col-lg-7 detail-section-content">
            <div className="detail-content">
              <h1 className="detail-content-title fs-6" >
                {detail?.category?.name} / {detail?.subcategoryname?.name}
              </h1>
              <p className="detail-content-para">{detail?.description}</p>
              <div className="detail-content-price text-danger">
                Rs.{detail?.saleprice}/-
              </div>
              <Suspense fallback={<div>Loading tabs...</div>}>
                <Tabs defaultActiveKey="specification" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="specification" title="Specification" className="text-danger">
                    <Specification detail={detail} />
                  </Tab>
                  <Tab eventKey="reviews" title={`Reviews (${detail?.reviews?.length || 0})`} className="text-danger">
                    <Reviews productId={_id} comments={detail?.reviews} />
                  </Tab>
                </Tabs>
              </Suspense>
              <div className="d-flex flex-wrap add-to-buttons">
                <Suspense fallback={<div>Loading button...</div>}>
                  <AddCartButton type="button" data={detail} />
                </Suspense>
                <Suspense fallback={<div>Loading button...</div>}>
                  <WishlistButton type="button" data={detail} className="mx-2" />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<div>Loading related products...</div>}>
        <RelatedProduct detail={detail} id={_id} />
      </Suspense>
      <Suspense fallback={<div>Loading sections...</div>}>
        <KeepUp />
        <ContactSection />
      </Suspense>
    </>
  );
}

export default LandingPageInnerPage;

