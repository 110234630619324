// import React, { useEffect, useState } from "react";
// import loginLogo from "../../Assets/Images/brand-logo.svg";
// import "../../Assets/Images/loginPage-banner.jpg";
// import "../LoginPage/LoginPage.scss";
// import { BsArrowLeftCircle } from "react-icons/bs";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../utils/index";
// import OtpInput from "react-otp-input";
// import Swal from "sweetalert2/dist/sweetalert2.js";

// function OtpVerification() {
//   const [otp, setOtp] = useState("");
//   const navigate = useNavigate();

//   const storedGuestProducts = JSON.parse(localStorage.getItem("CartProducts"));

//   const [isDisabled, setIsDisabled] = useState(false);
//   const [timer, setTimer] = useState(0);
//   const [intervalId, setIntervalId] = useState(null);

//   const handleResendOTP = () => {
//     const savedEmail =
//       localStorage.getItem("email") || sessionStorage.getItem("email");

//     // Send OTP request
//     axios
//       .post(`${API_BASE_URL}/user/resendotp`, { email: savedEmail })
//       .then((response) => {
//         // console.log("response from user resend OTP", response.data);
//         Swal.fire({
//           icon: "success",
//           title: "OTP send Successfully",
//           showConfirmButton: false,
//           timer: 3000,
//           text: response.data.message,
//         });
//       });

//     // Start the timer
//     setIsDisabled(true);
//     setTimer(60); // Set timer duration in seconds (e.g., 60 seconds)

//     // Set up the interval
//     const id = setInterval(() => {
//       setTimer((prev) => {
//         if (prev <= 1) {
//           clearInterval(id);
//           setIsDisabled(false); // Enable the button when the timer ends
//           return 0;
//         }
//         return prev - 1;
//       });
//     }, 1000);

//     setIntervalId(id);
//   };

//   useEffect(() => {
//     // Clean up interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [intervalId]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       otp: otp,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     axios
//       .post(`${API_BASE_URL}/otpverification`, {
//         otp: otp,
//         cartItems: storedGuestProducts?.map((item) => ({
//           // productId: item?.productId,
//           productId: item?.productId,
//           quantity: item?.quantity,
//         })),
//       })
//       .then((result) => {
//         console.log("result otp", result?.data);

//         const userId = result?.data?.token;
//         const refreshToken = result?.data?.refreshToken;

//         localStorage.setItem("userId", userId);
//         localStorage.setItem("refreshToken", refreshToken);

//         navigate("/");
//         Swal.fire({
//           icon: "success",
//           title: "Login Success",
//           showConfirmButton: false,
//           timer: 1500,
//         });

//         // localStorage.setItem("CartProducts", [])
//         // localStorage.setItem("CartProducts", JSON.stringify([]));
//         // window.location.reload(false);
//       })
//       .catch((error) => {
//         Swal.fire({
//           icon: "error",
//           title: "Login Failed",
//           showConfirmButton: true,
//           timer: 5000,
//           text: error.response.data.message,
//         });
//       });
//   };

//   return (
//     <div className="login-page">
//       <div className="login-page-inner">
//         <div className="text-center">
//           <img className="login-logo" src={loginLogo} alt="loginLogo" />
//         </div>
//         <form className="right " name="RegForm" onSubmit={handleSubmit}>
//           <div className="login-title" style={{ width: "70%" }}>
//             <Link to="/loginpage">
//               <BsArrowLeftCircle className="login-title-icon" />
//             </Link>
//             <h6>OTP Verification</h6>
//           </div>
//           <div className="confome-number">
//             <p className="m-0">Enter the OTP sent to Your Email</p>
//           </div>

//           <div className="otp-container">
//             <OtpInput
//               value={otp}
//               onChange={setOtp}
//               numInputs={4}
//               renderSeparator={<span>-</span>}
//               renderInput={(props) => <input {...props} />}
//             />
//           </div>

//           <div className="resendotp">
//             <p className="m-0">Didn’t receive the OTP?</p>
//             <button
//               className="resendotp-button mx-1"
//               onClick={handleResendOTP}
//               disabled={isDisabled}
//               style={{
//                 cursor: isDisabled ? "not-allowed" : "pointer",
//                 background: "none", // Remove background color
//                 border: "none", // Remove border
//               }}
//             >
//               {isDisabled ? `Resend OTP (${timer}s)` : "Resend OTP"}
//             </button>
//           </div>
//           <div className="d-flex justify-content-center">
//             <button
//               type="submit"
//               value="send"
//               name="Submit"
//               className="submit-button"
//             >
//               Verify
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default OtpVerification;

import React, { useEffect, useState } from "react";
import loginLogo from "../../Assets/Images/brand-logo.svg";
import "../../Assets/Images/loginPage-banner.jpg";
import "../LoginPage/LoginPage.scss";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/index";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Cookies from 'js-cookie';

function OtpVerification() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const storedGuestProducts = JSON.parse(localStorage.getItem("CartProducts"));

  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);


  const handleResendOTP = () => {
  
    const savedEmailOrMobile = Cookies.get("email") || sessionStorage.getItem("email");
  
  
    const isMobile = /^[0-9]{10}$/.test(savedEmailOrMobile);
  
   
    const payload = isMobile
      ? { mobile: savedEmailOrMobile }
      : { email: savedEmailOrMobile };
  
    
    axios
      .post(`${API_BASE_URL}/user/resendotp`, payload)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "OTP sent successfully",
          showConfirmButton: false,
          timer: 3000,
          text: response.data.message,
        });
      });
  
    
    setIsDisabled(true);
    setTimer(60); 
  
   
    const id = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(id);
          setIsDisabled(false); 
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  
    setIntervalId(id);
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const savedEmailOrMobile =
      localStorage.getItem("email") || sessionStorage.getItem("email");

    const isMobile = /^[0-9]{10}$/.test(savedEmailOrMobile);

    axios
      .post(`${API_BASE_URL}/otpverification`, {
        otp: otp,
        cartItems: storedGuestProducts?.map((item) => ({
          productId: item?.productId,
          quantity: item?.quantity,
        })),
        ...(isMobile ? { mobile: savedEmailOrMobile } : { email: savedEmailOrMobile }),
      })
      .then((result) => {
        const userId = result?.data?.token;
        const refreshToken = result?.data?.refreshToken;

        localStorage.setItem("userId", userId);
        localStorage.setItem("refreshToken", refreshToken);

        navigate("/");
        Swal.fire({
          icon: "success",
          title: "Login Success",
          showConfirmButton: false,
          timer: 1500,
        });

      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          showConfirmButton: true,
          timer: 5000,
          text: error.response.data.message,
        });
      });
  };

  return (
    <div className="login-page">
      <div className="login-page-inner">
        <div className="text-center">
          <img className="login-logo" src={loginLogo} alt="loginLogo" />
        </div>
        <form className="right " name="RegForm" onSubmit={handleSubmit}>
          <div className="login-title" style={{ width: "70%" }}>
            <Link to="/login">
              <BsArrowLeftCircle className="login-title-icon" />
            </Link>
            <h6>OTP Verification</h6>
          </div>
          {/* <div className="confome-number">
            <p className="m-0">
              Enter the OTP sent to Your{" "}
              {localStorage.getItem("email") ||
              sessionStorage.getItem("email")
                ? "Email"
                : "Mobile Number"}
            </p>
          </div> */}
<div className="confome-number">
  <p className="m-0">
    Enter the OTP sent to Your{" "}
    {(() => {
      const email = localStorage.getItem("email") || sessionStorage.getItem("email");
      
    
      if (email && !isNaN(Number(email))) {
        return "Mobile Number";
      }
      
      return "Email";
    })()}
  </p>
</div>

          <div className="otp-container">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>

          <div className="resendotp">
            <p className="m-0">Didn’t receive the OTP?</p>
            <button
              className="resendotp-button mx-1"
              onClick={handleResendOTP}
              disabled={isDisabled}
              style={{
                cursor: isDisabled ? "not-allowed" : "pointer",
                background: "none",
                border: "none",
              }}
            >
              {isDisabled ? `Resend OTP (${timer}s)` : "Resend OTP"}
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              value="send"
              name="Submit"
              className="submit-button"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OtpVerification;
