import React, { useContext, useEffect, useState } from "react";
import userContext from "../../../components/UseContext/UseContext";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";

import ViewSubProduct from "./ViewSubProduct";
import { useParams } from "react-router-dom";

function SubProducts() {
  const subProductsData = useContext(userContext);

  const productName=useParams()
  // console.log("params name checking",productName)

  const fastner = "65e9bffbe2a016938d79a546";

  const [categoryName, setCateGoryName] = useState("");

  const [subCategories, setSubCategories] = useState([]);


  function capitalizeFirstLetter(string) {
    if (!string) return string; 
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  // console.log(capitalizeFirstLetter(productName.slug),"sdsdsdsdsdsdsdsds")
 
  useEffect(() => {
    axios
      // .get(
      //   `${API_BASE_URL}/category/admin/${
      //     subProductsData.subProductdatas
      //       ? subProductsData.subProductdatas
      //       : fastner
      //   }`
      // )
      .get(
        `${API_BASE_URL}/category/user/${capitalizeFirstLetter(productName.slug)}`
      )
      .then((response) => {
        setSubCategories(response?.data?.subcategories);
        // console.log("sub categories names verifing",response?.data?.subcategories)
        setCateGoryName(response?.data?.subcategories[0]?.category?.name);
// console.log("product name finding",response?.data)
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      });
  }, []);

  return (
    <div>
        {/* <h1 style={{textAlign:"center"}}>{categoryName} Products</h1> */}
      {subCategories?.map((subcategoryName) => (
        <ViewSubProduct
          key={subcategoryName._id}
          subcategoryName={subcategoryName.name}
          categoryName={categoryName}
        />
      ))}
    </div>
  );
}

export default SubProducts;
