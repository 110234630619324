import React from "react";
import ToolsProductCards from "./ToolsProductCards";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import ToolsProductIdProvider from "../hoc/ToolsProductIdProvider";
import { Button } from "react-bootstrap";

function ToolsProducts({ categoryId, categoryName }) {
  return (
    <div className="products-wrapper px-2r py-4">
      <div className="products-heading-container ">
        <h4 className="product-heading  mt-2"> {categoryName} </h4>
       

        <Button className="view-all-products " style={{background:"#0443C0"}}>

        <Link to="/view-all-tools-products" >
          View All <IoIosArrowForward />
        </Link>
        </Button>
       
      </div>

      <ToolsProductCards categoryId={categoryId} />
    </div>
  );
}

export default ToolsProductIdProvider(ToolsProducts);
